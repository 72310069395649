<template>
  <div class="wrap">
    <SideBar class="sidebar" v-if="showNav && $store.state.auth.userData"></SideBar>
    <router-view class="router-view"/>
  </div>
  <div class="version">
    Dev 0.1.8
  </div>
</template>


<script>
import SideBar from '@/components/sidebar/SideBar.vue';

  export default  {
    components: {
      SideBar,
    },
    computed: {

      // Compute user data from store
      userData() {
        return this.$store.state.auth.userData;
      },

      // List of route names to exclude header and tab bars
      showNav() {
        const allowedRoutes = ['auth', 'set-profile'];
        if (!allowedRoutes.includes(this.$route.name)) {
          return true;
        } else {
          return false;
        }
      },

    }
  }

</script>



<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.wrap {
    display: flex;
    align-items: flex-start;
}
.router-view {
    width: 100%;
}
.sidebar {
  position: fixed;
  z-index: 17;
  background: #fff;
  height: 100%;
}

.version {
      position: fixed;
      bottom: 10px;
      right: 10px;
      font-size: 12px;
    }


</style>

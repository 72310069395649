<template>
    <div>


      <div v-if="!$store.state.toggle" class="burger">
        <div class="toggle-burger-closed" @click="toggleSidebar">
            <img class="logo" :src="logo" alt="Logo" >
        </div>
      </div>

      <div :class="{ 'sidebar-wrap': true, 'sidebar-hidden': !$store.state.toggle }">

        <div class="header">
                <router-link
                        @click="toggleSidebarMobile"
                        :to="{ name: 'main' }"
                        class="logo-wrap"
                        >
                <img class="logo" :src="logoFull" alt="Logo">
                </router-link>

                <div v-if="$store.state.toggle" class="toggle-burger" @click="toggleSidebar">
                    <img src="@/assets/icons/burger.svg" alt="store" />
                </div>
            </div>
        
        <div class="sidebar-container">

          <div class="sidebar-main-content">

            <section class="top">

                <router-link
                    @click="toggleSidebarMobile"
                    :to="{ name: 'newBot' }"
                    class="create-bot"
                    active-class="active-link-header"
                    >
                    <!-- <div class="icon">
                        <img src="@/assets/icons/add.svg" alt="add" />
                    </div> -->
                    <div class="title">
                        Create Avatar
                    </div>
                    <div class="arrow">
                        >
                    </div>
                </router-link>

                <router-link
                    @click="toggleSidebarMobile"
                    :to="{ name: 'main' }"
                    class="store"
                    active-class="active-link-header"
                    >
                    <!-- <div class="icon">
                        <img src="@/assets/icons/create.svg" alt="store" />
                    </div> -->
                    <div class="title">
                        Create Video
                    </div>
                    <div class="arrow">
                        >
                    </div>
                </router-link>

            </section>


            <videos></videos>


            <section class="options">

                <router-link
                    @click="toggleSidebarMobile"
                    :to="{ name: 'videos' }"
                    class="menu-item"
                    active-class="active-link"
                    >
                    <div class="icon">
                        <img src="@/assets/icons/videos.svg" alt="store" />
                    </div>
                    <div class="title">
                        All creation
                    </div>
                </router-link>

                <!-- <router-link
                    :to="{ name: 'main' }"
                    class="menu-item"
                    active-class="active-link">
                    <div class="icon">
                        <img src="@/assets/icons/create.svg" alt="store" />
                    </div>
                    <div class="title">
                       Create video
                    </div>
                </router-link> -->

                <!-- <router-link
                    :to="{ name: 'clips' }"
                    class="menu-item"
                    active-class="active-link">
                    <div class="icon">
                        <img src="@/assets/icons/clip.svg" alt="store" />
                    </div>
                    <div class="title">
                        Reusable clips
                    </div>
                </router-link> -->

                <router-link
                    @click="toggleSidebarMobile"
                    :to="{ name: 'myBots' }"
                    class="menu-item"
                    active-class="active-link"
                    >
                    <div class="icon">
                        <img src="@/assets/icons/add.svg" alt="store" />
                    </div>
                    <div class="title">
                        My avatars
                    </div>
                </router-link>

                <!-- <router-link
                    :to="{ name: 'profile' }"
                    class="menu-item"
                    active-class="active-link">
                    <div class="icon">
                        <img src="@/assets/icons/profile.svg" alt="store" />
                    </div>
                    <div class="title">
                        Profile
                    </div>
                </router-link> -->

                <router-link
                    @click="toggleSidebarMobile"
                    :to="{ name: 'settings' }"
                    class="menu-item"
                    active-class="active-link"
                    >
                    <div class="icon">
                        <img src="@/assets/icons/settings.svg" alt="store" />
                    </div>
                    <div class="title">
                        Settings
                    </div>
                </router-link>

                <router-link
                    @click="toggleSidebarMobile"
                    :to="{ name: 'logout' }"
                    class="menu-item"
                    active-class="active-link"
                    >
                    <div class="icon">
                        <img src="@/assets/icons/logout.svg" alt="store" />
                    </div>
                    <div class="title">
                        Logout
                    </div>
                </router-link>


            </section>
            
          </div>

        </div>
      </div>
    </div>
  </template>
  
  <script>
  import logo from '@/assets/logo/180-logo.png';
  import logoFull from '@/assets/logo/300-logo-hor.png';
  import Videos from '@/components/sidebar/Videos.vue';


  export default {
    components: {
        Videos,
    },
    data() {
      return {
        logo: logo,
        logoFull: logoFull,
        isMinimalSidebar: false,
      };
    },
    methods: {
        toggleSidebar() {
        this.$store.commit('toggleSidebar');
        },
        toggleSidebarMobile() {
            if (window.innerWidth <= 768) {
                this.$store.commit('toggleSidebar', false);
            }
        },
    
    },
  };
  </script>
  
  <style scoped>


  .sidebar-wrap {
    argin-left: 0px;
    width: 300px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #e4e7e7;
    overflow-y: hidden;
    opacity: 1;
    transition: margin-left 0.2s ease, opacity 1s ease;
  }
  
  .sidebar-hidden {
  margin-left: -300px;
  opacity: 0.3;
  }

  @media only screen and (max-width: 768px) {
   .sidebar-wrap{
        width: 100%;
        background: #ffffff;
        transition: opacity 0.2s ease;
        width: 100vw
    }
    .sidebar-hidden {
    margin-left: -700px;
    opacity: 0;
    }
}

  .sidebar-container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }


  .header {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    background-color: #f7f7f7;
    min-height: 50px;
    max-height: 50px;
    height: 50px;
    border-bottom: 1px solid #e4e7e7;
    align-items: center;
    box-shadow: rgb(184 184 184 / 15%) 3px 4px 7px 0px;
  }
  
  .logo-wrap {
    height: 100%;
    padding: 10px;
    margin-left: 2px;
  }
  
  .logo {
    height: 100%;
    max-width: 100%;
    margin: 0 auto;
  }
  .toggle-burger {
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.burger {
    position: fixed;
}
.toggle-burger-closed {
    cursor: pointer;
    padding: 7px;
    width: 46px;
    height: 44px;
    border-radius: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4px;
}
.toggle-burger img{
  width: 20px;
  height: 20px;
  opacity: 0.5;
}
section.creation {
    min-height: 380px;
}
section.top {
    display: flex;
    padding: 10px;
    grid-gap: 5px;
    gap: 7px;
    margin: 0;
    flex-direction: column;
}

.store, .create-bot {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 5px;
    background-color: #f7f7f7;
    border-radius: 5px;
    transition: background 0.3s ease;
    justify-content: center;
    background-image: linear-gradient(to right,#29C9FF,#D446FF);


}

.store:hover, .create-bot:hover {
    background-image: linear-gradient(to right,#2eb1dd,#b33dd6);

}
.title {
    font-size: 14px;
}
.icon img {
    width: 20px;
}
section {
    border-top: 1px solid #e4e7e7;
}

.menu-item {
    padding: 0.75rem 5px;
    display: flex;
    gap: 5px;
    align-items: center;
    border-top: 1px solid #e4e7e7;
    border-bottom: 1px solid #e4e7e7;
    transition: background 0.3s ease;
}
.menu-item:hover {
    background: #f7f7f7;
}

.menu-item .icon {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.375rem;
    height: 2.375rem;
}

.store .title, .create-bot .title {
    color:#ffffff !important;
}



.active-link {
  background-color: #f7f7f7;
}

.active-link-header {
    background-image: linear-gradient(to right,#2eb1dd,#b33dd6);
}
.menu-item .icon img {
    width: 20px;
}

.arrow {
    color: white;
    margin-top: 1px;
}

@media only screen and (max-width: 768px) {
}
  </style>
  
<template>
  <div class="video-container">
    <div class="custom-controls">
      <button class="btn-c" v-if="is_playing" @click="playPause">
        <img src="@/assets/icons/stop.svg" alt="stop" />
      </button>
      <button class="btn-c" v-if="!is_playing && !un_mute" @click="unMuteAndPlay">
        <img src="@/assets/icons/sound.svg" alt="unmute" />
      </button>
      <button class="btn-c" v-if="!is_playing && un_mute" @click="play">
        <img src="@/assets/icons/play.svg" alt="unmute" />
      </button>
      <button class="btn" @click="toCreateBot">Get started</button>
    </div>

    <video ref="myVideo" poster="/video/sampleIntro.png" autoplay muted loop preload="metadata">
      <source src="/video/sampleIntroM.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  </div>
</template>

<script>
export default {
  data() {
    return {
      is_playing: false,
      un_mute: false
    };
  },
  methods: {
    toCreateBot() {
      this.$router.push({ name: 'newBot' });
    },
    playPause() {
      const video = this.$refs.myVideo;
      if (video.paused) {
        this.is_playing = true;
        video.play();
      } else {
        this.is_playing = false;
        video.pause();
      }
    },
    unMuteAndPlay() {
      const video = this.$refs.myVideo;
      video.muted = false; // Unmute the video
      video.volume = 1;    // Set the volume to maximum
      this.is_playing = true;
      video.currentTime = 0; // Restart the video from the beginning
      video.removeAttribute('loop'); // Remove the loop attribute
      video.play();
      this.un_mute = true;
    },
    play() {
      const video = this.$refs.myVideo;
      video.muted = false; // Unmute the video
      video.volume = 1;    // Set the volume to maximum
      this.is_playing = true;
      video.removeAttribute('loop'); // Remove the loop attribute
      video.play();
      this.un_mute = true;
    },
  },
};
</script>


    
<style scoped>

.video-container {
  position: fixed;
  bottom: -10px;
  right: -15px;
  width: 100%;
  max-width: 480px;
  z-index: 15;
  display: flex;
  justify-content: center;
}

video {
  width: 100%;
  max-height: 250px;
  min-width: 300px;
  border-top-left-radius: 1000px;
  border-top-right-radius: 1000px;
}

.custom-controls .btn-c {
  background: #eeeeee;
  width: 40px;
  height: 40px;
  min-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dfdfdf;
  border-radius: 50px;
  box-shadow: rgba(126, 126, 126, 0.45) 3px 4px 5px 0px;
}

.btn-c img {
  width: 17px;
}

.custom-controls {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
  position: absolute;
    bottom: 30px;
    z-index: 16;
}


.btn {
  max-width: 130px;
  box-shadow: rgba(126, 126, 126, 0.45) 3px 4px 5px 0px;
}

@media only screen and (max-width: 768px) {
  
.video-container{
  right: 0px;
}
video {
    max-height: 210px;
}
}

</style>
<template>
    <div>
          <section class="section-wrap">
            <div class="title">
              Appearance
            </div>
            <div class="card">
              <div class="input">

              </div>
            </div>
          </section>

    </div>
  </template>
  
  <script>

  export default {
    components: {
    },
    
  };
  </script>
  
  
    
  <style scoped>

  .card {
    background: #f7f7f7;
    border-radius: 5px;
    padding: 20px;
    box-shadow: rgb(184 184 184 / 15%) 0px 4px 7px 0px;
}
section {
  margin: 15px 0;
}

.title {
  font-size: 14px;
}
  </style>
<template>
    <div>

      <TopBar v-if="$store.state.auth.userData"></TopBar>

      <div :class="{'main-wrapper-settings': $store.state.toggle, 'main-wrapper-settings-closed': !$store.state.toggle}">
        <h4>Settings</h4>
        <div class="sections">

          <Subscription></Subscription>

          <ApiKeys></ApiKeys>

          <Deafultbot></Deafultbot>

          <!-- <Appearance></Appearance> -->

          <Links></Links>

          <Others></Others>

        </div>

      </div>
    </div>
  </template>
  
  <script>
  import TopBar from '@/components/topbar/TopBar.vue';
  import ApiKeys from '@/components/settings/ApiKeys.vue';
  import Links from '@/components/settings/Links.vue';
  import Subscription from '@/components/settings/Subscription.vue';
  import DeafultBot from '@/components/settings/DeafultBot.vue';
  import Appearance from '@/components/settings/Appearance.vue';
  import Others from '@/components/settings/Others.vue';

  export default {
    components: {
      ApiKeys,
      TopBar,
      Links,
      Subscription,
      DeafultBot,
      Appearance,
      Others
    },
    
  };
  </script>
  
  
    
  <style scoped>

section {
  margin: 15px 0;
}


  </style>
<!-- <template>
  <div>
    <input type="file" @change="handleBackgroundImage" />
    <input type="file" @change="handleIconImage" />
    <div class="canvas-wrap">
      <canvas ref="canvas" @mousedown="handleMouseDown" @mousemove="handleMouseMove" @mouseup="handleMouseUp"></canvas>
    </div>
    <div>Icon X: {{ getIconXRelativeToImage() }}, Icon Y: {{ getIconYRelativeToImage() }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      backgroundImage: null,
      iconImage: null,
      iconX: 0,
      iconY: 0,
      isDragging: false,
      startX: 0,
      startY: 0,
      canvasWidth: 0,
      canvasHeight: 0,
      imageWidth: 0,
      imageHeight: 0,
    };
  },
  methods: {
    handleBackgroundImage(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.backgroundImage = reader.result;
          this.drawCanvas();
        };
        reader.readAsDataURL(file);
      }
    },
    handleIconImage(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.iconImage = reader.result;
          this.drawCanvas();
        };
        reader.readAsDataURL(file);
      }
    },
    drawCanvas() {
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext('2d');
      const image = new Image();
      const icon = new Image();

      image.src = this.backgroundImage;
      icon.src = this.iconImage;

      image.onload = () => {
        this.imageWidth = image.width;
        this.imageHeight = image.height;

        this.canvasWidth = canvas.offsetWidth;
        this.canvasHeight = (this.imageHeight / this.imageWidth) * this.canvasWidth;

        canvas.width = this.canvasWidth;
        canvas.height = this.canvasHeight;

        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

        if (this.iconImage) {
          const iconHeight = this.canvasHeight * 0.4;
          ctx.drawImage(icon, this.iconX, this.iconY, iconHeight, iconHeight);
        }
      };
    },
    handleMouseDown(event) {
      const canvas = this.$refs.canvas;
      const canvasRect = canvas.getBoundingClientRect();
      const mouseX = event.clientX - canvasRect.left;
      const mouseY = event.clientY - canvasRect.top;

      if (
        mouseX >= this.iconX &&
        mouseX <= this.iconX + (this.canvasHeight * 0.4) &&
        mouseY >= this.iconY &&
        mouseY <= this.iconY + (this.canvasHeight * 0.4)
      ) {
        this.isDragging = true;
        this.startX = mouseX - this.iconX;
        this.startY = mouseY - this.iconY;
      }
    },
    handleMouseMove(event) {
      if (this.isDragging) {
        const canvas = this.$refs.canvas;
        const canvasRect = canvas.getBoundingClientRect();
        const ratio = this.imageWidth / this.canvasWidth;

        this.iconX = (event.clientX - canvasRect.left - this.startX) * ratio;
        this.iconY = (event.clientY - canvasRect.top - this.startY) * ratio;

        this.drawCanvas();
      }
    },
    handleMouseUp() {
      this.isDragging = false;
    },
    getIconXRelativeToImage() {
      return (this.iconX / this.canvasWidth) * this.imageWidth;
    },
    getIconYRelativeToImage() {
      return (this.iconY / this.canvasHeight) * this.imageHeight;
    },
  },
};
</script>

<style scoped>
.canvas-wrap {
  width: 50%;
  overflow: hidden;
}

canvas {
  display: block;
  width: 100%;
  height: auto;
  border: 1px solid #000;
  cursor: pointer;
}
</style> -->






<template>
  <div>

  </div>
</template>

<script>
  export default {
    
  }
</script>

<style scoped>

</style>

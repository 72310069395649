<template>
      <div @click="removeError" class="background-img" v-if="previewType === 'image'">
        <img :src="url" alt="Preview" />
      </div>
      <div @click="removeError" v-else-if="previewType === 'video'">
        <video ref="myVideo" @click="togglePlay">
          <source :src="url" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div @click="removeError" class="background-img" v-else-if="previewType === 'youtube'">
        <iframe
          max-width="30%"
          :src="youtubeEmbedUrl + '?controls=0'"
          frameborder="0"
          allowfullscreen
          ref="youtubeIframe"
        ></iframe>
      </div>
      <div @click="removeError" class="background-img" v-else-if="urlPreviewError">
        {{ urlPreviewError }}
      </div>
  </template>
  
  <script>
  export default {
    props: {
        url: String,
    },
    data() {
      return {
        previewType: '', // 'image', 'video', 'youtube'
        youtubeEmbedUrl: '',
        urlPreviewError: '',
      };
    },
    methods: {
      removeError() {
      this.urlPreviewError = '';
    },
      togglePlay() {
      const video = this.$refs.myVideo;

      if (video.paused || video.ended) {
        video.play();
      } else {
        video.pause();
      }
    },
      triggerPreview() {
        this.previewUrl();
        },  
      async previewUrl() {
        this.previewType = ''; // Reset preview type
        this.youtubeEmbedUrl = ''; // Reset YouTube embed URL
        this.urlPreviewError = ''; // Reset error message
  
        // Check if the URL is for an image
        if (/\.(jpeg|jpg|gif|png)$/.test(this.url)) {
          this.previewType = 'image';
        }
        // Check if the URL is for a video (you may need to enhance this check based on supported video formats)
        else if (/\.(mp4|webm|ogg)$/.test(this.url)) {
          this.previewType = 'video';
        }
        // Check if the URL is a YouTube link
        else if (
          /^(https?:\/\/)?(www\.)?(youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/.test(
            this.url
          )
        ) {
          this.previewType = 'youtube';
          // Generate YouTube embed URL
          this.youtubeEmbedUrl = `https://www.youtube.com/embed/${RegExp.$4}`;
        }
        // If the URL is unsupported, try fetching and detecting the content type
        else {
          try {
            const response = await fetch(this.url, { method: 'HEAD' });
  
            // Check if the response is successful
            if (response.ok) {
              const contentType = response.headers.get('content-type');
  
              // Check content type and set preview type accordingly
              if (contentType && contentType.startsWith('image')) {
                this.previewType = 'image';
              } else if (contentType && contentType.startsWith('video')) {
                this.previewType = 'video';
              } else if (contentType && contentType.startsWith('text/html')) {
                // Check if it's a YouTube video page
                const html = await response.text();
                if (html.includes('www.youtube.com/embed/')) {
                  this.previewType = 'youtube';
                  this.youtubeEmbedUrl = `https://www.youtube.com/embed/${html
                    .split('www.youtube.com/embed/')[1]
                    .split('"')[0]}`;
                }
              } else {
                this.urlPreviewError = 'Unsupported type';
              }
            } else {
              this.urlPreviewError = 'Unsupported URL';
            }
          } catch (error) {
            this.urlPreviewError = 'Unsupported URL';
          }
        }
      },
    },
  };
  </script>
  
  <style scoped>

.background-img {
    display: flex;
    align-items: center;
}
.background-img img {
    max-width: 100px;
    max-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}
video {
  max-width: 133px;
    border-radius: 10px;
}
iframe {
    max-width: 133px;
    max-height: 80px;
    border-radius: 10px;
}
  </style>
  
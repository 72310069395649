<template>
    <div>
        <div class="topbar">
        <div class="info">
            Credit: {{ $store.state.auth.userData.credit }}
        </div>
        </div>
    </div>
</template>

<script>
    export default {
    
    }
</script>

<style scoped>
.topbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 50px;
    padding: 0 10px;
    font-size: 14px;
    background: white;
    position: fixed;
    width: 100%;
    z-index: 11;
    top: 0;
}

@media only screen and (max-width: 768px) {
    .topbar {
        box-shadow: rgb(184 184 184 / 4%) 3px 4px 7px 0px;
    }
}
</style>
<template>
  <div>
    <TopBar></TopBar>
      <div :class="{'main-wrapper': $store.state.toggle, 'main-wrapper-closed': !$store.state.toggle}">

        <div v-if="ready">
          <div class="search-wrap">
            <input v-model="search" name="search" type="search" placeholder="Search for bot">
          </div>
          <div class="tags">
            <div class="tag"
                v-if="tags"
                v-for="(tag, index) in tags"
                active-class="tag">
                <li class="tag-wrapper">
                  {{ tag }}
                </li>
              </div>
          </div>

          <div class="bots">
            <router-link
                v-if="bots"
                v-for="(bot, index) in bots"
                :key="bot.uid"
                :to="{ name: 'storeBot', params: { id: bot.uid } }"
                active-class="active-link">
                <li class="item-wrapper">
                    <div class="image">
                        <img class="icon" :src="bot.thumbnail" alt="bots" loading="lazy">
                    </div>
                    <div class="side">
                        <div class="top-row">
                            <div class="name">
                                {{ bot.name }}
                            </div>
                        </div>
                        <div class="bottom-row">
                            <div class="info">
                                Gender: {{ bot.gender }} |  Age: {{ bot.age }} |  Accent: {{ bot.accent }}
                            </div>
                        </div>
                    </div>
                    <div class="arrow">
                            >
                    </div>
                </li>
            </router-link>
          </div>
        </div>  

        <div v-if="!ready">
          <div class="row">
              <div class="image">
                <img src="@/assets/logo/180-logo.png" alt="No-videos" />
              </div>
              <div class="head">
                Under maintenance
              </div>
              <div class="small">
                Avatar store will be available soon.
              </div>
          </div>
        
        </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios';
import TopBar from '@/components/topbar/TopBar.vue';

export default {
components: {
  TopBar,
},
data() {
  return {
    ready: false,
    search: '',
    loading: false,
    error: '',
    pageTitle: '',
    bots: [],
    tags: [],
  };
},
created() {
  this.fetchBots(); // Corrected method name
},
methods: {
  async fetchBots() { // Corrected method definition
    this.loading = true;

    try {
      // Get the access token from localStorage
      const accessToken = localStorage.getItem('access_token');

      // Make a GET request to your backend API with the authorization header
      const response = await axios.get('/v1/bot-store', {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      // Assuming your API returns data as a list of keys
      const bots_data = response.data.data.bots;
      const tags_data = response.data.data.tags;
      this.bots = bots_data;
      this.tags = tags_data;
      this.loading = false; // Set loading to false after successful data fetch
    } catch (error) {
      this.loading = false; // Set loading to false in case of an error
      this.error = 'Error fetching data'; // Set the error message
      console.error('Error fetching:', error);
    }
  },
},
};
</script>


<style scoped>
.main-wrapper {
padding: 50px;
min-height: calc(100vh - 100px);
display: flex;
flex-direction: column;
justify-content: center;
overflow-x: hidden;
margin-left: 300px;
align-items: center;
position: relative;
}
.main-wrapper > * {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.item-wrapper {
  width: 100%;
  height: 120;
  border-bottom: 1px solid #e4e7e7;
  display: flex;
  align-items: center;
  padding: 15px;
  justify-content: flex-start;
  gap: 25px;
}
img.icon {
  width: 70px;
}

.top-row, .bottom-row {
  width: 100%;
  display: flex;
}
.side {
  width: 100%;
}
.arrow {
  width: 15px;
  display: flex;
  justify-content: flex-end;
}

a {
  width: 100%;
}
.info {
  font-size: 12px;
}
.bots {
  width: 100%;
}
.search-wrap {
    width: 100%;
    margin: 10px 0px;
}
.tags {
    display: flex;
    gap: 10px;
    width: 100%;
    margin: 10px 0px;
    flex-wrap: wrap;
}

.tag {
  border: 1px solid rgba(146, 146, 146, 0.4);
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 13px;
}

.tag:hover {
  background: #f7f7f7;
}


.image img {
  width: 100px;
}
</style>
<template>
  <div>
    <section class="section-wrap">
      <div class="title">
        API key
      </div>
      <div :class="{'card': apiKeys.length > 0, 'card-loading': apiKeys.length === 0}">
        
        <div v-if="apiKeys.length === 0" class="loading">
            <div class="spinner"></div>
        </div>

        <div v-if="apiKeys.length > 0" class="key-item" v-for="apiKey in apiKeys" :key="apiKey">

          <div class="key" @click="copyKey(apiKey)">
            {{ displayKey(apiKey) }}
          </div>

          <div class="btns">
            <button class="eye" @click="toggleShowKey(apiKey)">
              <img src="@/assets/icons/view.svg" alt="show" />
            </button>
            <!-- <button @click="copyKey(apiKey)">
              <img src="@/assets/icons/copy.svg" alt="copy" />
            </button> -->
            <button class="re" @click="generateKey(apiKey)">
              <img src="@/assets/icons/re.svg" alt="generate" />
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

  
  <script>
  import axios from 'axios';
  import { ref } from 'vue';

  export default {

    data() {
      return {
        apiKeysa: []
      }
    },
    methods: {
      async generateKey(key) {
        try {
          const accessToken = localStorage.getItem('access_token');
          console.log(key);

          // Make a POST request to generate a new key
          await axios.post('/generate-key', { key }, { headers: { 'Authorization': `Bearer ${accessToken}` } });

          // Fetch and update the list of API keys
          await this.fetchApiKeys();

          console.log('New key generated successfully.');
        } catch (error) {
          console.error('Error generating key:', error);
        }
      },
    },

    setup() {
      const apiKeys = ref([]);
      const showKeyMap = ref({});

      const fetchApiKeys = async () => {
        try {
          const accessToken = localStorage.getItem('access_token');

          // Make a GET request to fetch the updated list of API keys
          const response = await axios.get('/api-key', { headers: { 'Authorization': `Bearer ${accessToken}` } });

          // Update the apiKeys reactive property
          apiKeys.value = response.data.api_keys;

          // Initialize showKeyMap with default values (all keys initially hidden)
          apiKeys.value.forEach((key) => {
            showKeyMap.value[key] = false;
          });
        } catch (error) {
          console.error('Error fetching API keys:', error);
        }
      };

      const displayKey = (apiKey) => {
        return showKeyMap.value[apiKey] ? apiKey : '******' + apiKey.slice(-4);
      };

      const toggleShowKey = (apiKey) => {
        showKeyMap.value[apiKey] = !showKeyMap.value[apiKey];
      };

      const showKey = (apiKey) => {
        return showKeyMap.value[apiKey] ? 'Hide' : 'Show';
      };

      const copyKey = (apiKey) => {
        console.log('Copied:', apiKey);
      };

      // Fetch API keys on component mount
      fetchApiKeys();

      // Return reactive properties and methods
      return {
        apiKeys,
        showKeyMap,
        displayKey,
        toggleShowKey,
        showKey,
        copyKey,
        fetchApiKeys, // Expose fetchApiKeys method for external use
      };
    },
  };
</script>

  
  
    
  <style scoped>
.title {
  font-size: 14px;
}

.key-item {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    align-content: flex-end;
    flex-wrap: wrap;
}
.btns button {
    background: #dcdcdc;
    padding: 10px;
    color: #fff;
    fill: #fff;
    border: none;
    justify-content: center;
    cursor: pointer;
    text-align: center;
    margin-right: 5px;
    margin-top: 5px;
    border-radius: 5px;
}
.eye img {
  width: 20px;
}

.re img {
  width: 20px;
  padding: 2px;
}

.btns button {
    display: inline-flex;
}
.card {
    background: #f7f7f7;
    border-radius: 5px;
    padding: 20px;
    box-shadow: rgb(184 184 184 / 15%) 0px 4px 7px 0px;
    min-height: 70px;
}

.card-loading {
    background: #f7f7f7;
    border-radius: 5px;
    padding: 20px;
    box-shadow: rgb(184 184 184 / 15%) 0px 4px 7px 0px;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.key {
    user-select: text;
    overflow: auto;
}
.spinner {
    width: 20px;
    height: 20px;
}
  </style>
<template>
    <div v-if="bots.length > 0">
        <div v-if="!loading && this.$store.state.settingsData.selected_bot_is_ready" class="bot-area">
            <img  :src=this.$store.state.settingsData.selected_bot_tb alt="avatar" />
        </div>
        <div v-if="!loading && !this.$store.state.settingsData.selected_bot_is_ready" class="bot-area in-process">
          <img  :src=this.$store.state.settingsData.selected_bot_tb alt="avatar" />
          <div  class="spinner"></div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
    export default {
  data() {
    return {
        accessToken: localStorage.getItem('access_token'),
        loading: true,
        bots: [],
        selected_bot: '',
        selected_bot_tb: this.$store.state.settingsData.selected_bot_tb,
        selected_bot_is_ready: this.$store.state.settingsData.selected_bot_is_ready,
    }
  },      
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;

        // Make a GET request to the API with the authorization header
        const response = await axios.get('/v1/get-bot', {
          headers: {
            'Authorization': `Bearer ${this.accessToken}`,
          },
        });

        // Check if bots data is available in the response
        if (response.data && response.data.data && response.data.data.bots) {
          this.bots = response.data.data.bots;

          // Check if bots array is not empty
          if (this.bots.length > 0) {
            this.selected_bot = this.bots[0].uid;
            this.selected_bot_tb = this.bots[0].thumbnail;
            this.selected_bot_is_ready = this.bots[0].is_ready;

            this.$store.dispatch('updateSettingsData', {
            selected_bot: this.selected_bot,
            selected_bot_tb: this.selected_bot_tb,
            selected_bot_is_ready: this.selected_bot_is_ready,
            bots: this.bots,

        });

          } else {
            // Handle the case where bots array is empty
            this.selected_bot = null; // or any default value you prefer
          }
        } else {
          // Handle the case where bots data is not available
          this.bots = [];
          this.selected_bot = null; // or any default value you prefer

        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        this.loading = false;

      }
    },
  },
    }
</script>

<style scoped>
.bot-area {
  box-shadow: 3px 4px 7px 0 hsl(0deg 0% 72% / 26%);
  border: 0.1px solid #cfd3d347;
  width: 80px;
  height: 80px;
  min-width: 80px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  border-radius: 62px;
  overflow: hidden;
  position: relative;
  align-items: flex-end;
  background: #efefef99;
  transition: background .3s ease;
}

.loading {
    align-items: center;
}

.bot-area:hover {
  background: #efefef;
}
.bot-area img {
  width: 68px;
}

.spinner {
  width: 25px;
    height: 25px; 
    position: absolute;
    z-index: 1;
    top: calc(50% - 12.5px); 
    left: calc(50% - 12.5px); 
}

.in-process img {
    filter: blur(3.5px);
}
</style>
<template>
    <div>
      <Delete item-name="video" deletedText='' v-if="showDeleteDialog" @confirm="deleteVideo" @cancel="closeDeleteDialog" />  
      <TopBar></TopBar>
      <div :class="{'main-wrapper': $store.state.toggle, 'main-wrapper-closed': !$store.state.toggle}">  
        <div class="loading" v-if="loading"><div class="spinner"></div></div>

        <div class="wrap" v-else>

          <router-link :to="{ name: 'bot', params: { id: video.bot_uid } }">
          <div class="bot-wrap">
            <div class="image">
              <img v-if="video" :src="video.main_clip_bot_thumbnail" alt="Thumbnail" loading="lazy">
            </div>
            <div class="name">
              {{ video.main_clip_bot_name }}
            </div>
          </div>
          </router-link>

          <div class="card">
              <div class="video-info">
                <div class="title id">
                  {{ video.main_clip_text }}
                </div>
                <div class="image-wrap-create">
                <img v-if="video && video.video_thumbnail" :src="video.video_thumbnail" alt="Thumbnail" loading="lazy">
              </div>
              <div class="date">
                  Created: {{ video.created_date }}
                </div>
              </div>
          </div>



          <router-link :to="{ name: 'main' }">
          <div class="bot-wrap">
            <div class="image">
              <img v-if="video" :src="logo" alt="Thumbnail" loading="lazy">
            </div>
            <div class="name">
              Aipixy
            </div>
          </div>
          </router-link>

          <div class="card">
              <div class="video-info">
                <div class="title id">
                  Video UID: {{ video.uid }}
                </div>
                <!-- <div class="title">
                  Number of clips: {{ video.clips }}
                </div> -->
                <div class="title id" v-if="video && !video.video_url" >
                  Status: In process
                </div>
                <div class="title id" v-if="video && video.video_url" >
                  Status: Done
                </div>
              </div>
              <div class="image-wrap">
                <div v-if="!video.video_url">
                  
                  <div class="loader">

                    <div class="cell d-0"></div>
                    <div class="cell d-1"></div>
                    <div class="cell d-2"></div>

                    <div class="cell d-1"></div>
                    <div class="cell d-2"></div>
                    
                    
                    <div class="cell d-2"></div>
                    <div class="cell d-3"></div>
                    
                    
                    <div class="cell d-3"></div>
                    <div class="cell d-4"></div>
                    
                  </div>



                </div>
              </div>
              <div v-if="video && video.video_url" class="video-container" @mouseover="showDownloadButton" @mouseleave="hideDownloadButton">
                <video playsinline :key="video.video_url" width="800" controls :poster="video.video_thumbnail" loop disable>
                    <source :src="video.video_url" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
              </div>
          </div>

          <div v-if="video && !video.video_url" class="btn-row">
            
            <div @click="openDeleteDialog" class="video-btns trash">
              <div class="title">
                Delete
              </div>
              <div class="icon">
                <img src="@/assets/icons/trash.svg" alt="download" />
              </div>
            </div>

          </div>  

          <div v-if="video && video.video_url" class="btn-row">

            <!-- <div class="video-btns">
              <div class="title">
                Share
              </div>
              <div class="icon">
                <img src="@/assets/icons/share.svg" alt="share" />
              </div>
            </div> -->

            <!-- <div @click="downloadVideo" class="video-btns">
              <div class="title">
                Download
              </div>
              <div class="icon">
                <img src="@/assets/icons/download.svg" alt="download" />
              </div>
            </div> -->

            <div @click="openDeleteDialog" class="video-btns trash">
              <div class="title">
                Delete
              </div>
              <div class="icon">
                <img src="@/assets/icons/trash.svg" alt="download" />
              </div>
            </div>

          </div>
         
        </div>
        </div>
    </div>
    
  </template>
  
  <script>
  import axios from 'axios';
  import TopBar from '@/components/topbar/TopBar.vue';
  import Delete from '@/components/Delete.vue';
  import logo from '@/assets/logo/180-logo.png';

  export default {
    data() {
      return {
        video: [],
        loading: true,
        error: null,
        showDeleteDialog: false,
        logo: logo,
      };
    },
    components: {
      TopBar,
      Delete,
    },
    watch: {
      '$route.params.id': function(newId, oldId) {
        this.fetchVideoData();
      },
    },
    created() {
      this.fetchVideoData();
    },
    methods: {
      fetchVideoData() {
        // Get the access token from localStorage
        const accessToken = localStorage.getItem('access_token');
        const videoId = this.$route.params.id;

        // Make the Axios call directly
        axios.get(`/v1/get-video/${videoId}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        })
        .then(response => {
          const fetchedVideo = response.data.data;
          // Update component state with the fetched data
          this.video = fetchedVideo;
        })
        .catch(error => {
          this.$router.push({ name: 'videos' });
        })
        .finally(() => {
          // Update loading state to false
          this.loading = false;
        });
      },

      openDeleteDialog() {
      // Open the delete dialog
      this.showDeleteDialog = true;
    },

    closeDeleteDialog() {
      // Close the delete dialog
      this.showDeleteDialog = false;
    },
    deleteVideo() {
      // Delete video logic

      this.showDeleteDialog = false;
      this.loading = true;
      const VideoId = this.video.uid;
      const accessToken = localStorage.getItem('access_token');
      const userVideos = this.$store.state.video.userVideos;

      console.log(userVideos);

      axios.delete(`/v1/video/${VideoId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      })
        .then(() => {
          // Bot deleted successfully, navigate to another page or perform any other actions
          this.$store.dispatch('getUserVids');
        })
        .catch(error => {
          console.error('Error deleting video:', error);
          this.loading = false;
        })
        .finally(() => {
          if (userVideos && userVideos.length > 1) {
            this.$router.push({ name: 'videos' });
          } else {
            this.$router.push({ name: 'main' });
          }

        });
    },
    downloadVideo() {
    const videoId = this.video.uid;
    const accessToken = localStorage.getItem('access_token');

    axios.get(`/download_video/${videoId}`, {
        headers: {
            'Authorization': `Bearer ${accessToken}`,
        },
        responseType: 'blob', // Specify the response type as blob
    })
    .then(response => {
        // Create a blob from the response data
        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        // Create a link element and simulate a click to trigger the download
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${videoId}.mp4`;
        link.click();

        console.log('Video downloaded successfully');
    })
    .catch(error => {
        console.error('Error downloading video:', error);
    });
    }
    },
  };
</script>

  
<style scoped>
video {
  border-radius: 10px;
  width: 100%;
  max-width: 1200px;
}

.btn-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.video-container {
    width: 100%;
    display: flex;
    margin: 10px 0;
}

.video-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f7f7f7;
    border-radius: 5px;
    padding: 5px;
    gap: 5px;
}

.video-btns:hover {
  background: #eeeeee;
}

.video-btns img {
    width: 20px;
}

.icon {
    display: flex;
}

.title {
    font-size: 14px;
    text-align: left;
}

.card {
  background: #f7f7f7;
  width: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 35px;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: rgb(184 184 184 / 15%) 3px 4px 7px 0px;
  margin-bottom: 25px;
}


.spinner {
  min-width: 40px;
    min-height: 40px;
}
.card img{
  max-width: 600px;
  width: 90%;
  border-radius: 10px;
}
.wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.video-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.image-wrap {
    position: relative;
    display: flex;
    justify-content: flex-start;
    width: 100%;
}
.image-wrap-create {
  display: flex;
  margin: 10px 0;
}

.image-wrap-create img{
  max-height: 320px;
}

.date {
    font-size: 12px;
}

.bot-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
}
.bot-wrap img {
  width: 30px;
    min-height: 30px;
}
.image {
    background: #f7f7f7;
    padding: 7px;
    border-radius: 10px;
    display: flex;
    min-height: 30px;
}

.title.id {
  user-select: text;
}

.trash img {
  width: 15px;
}

@media only screen and (max-width: 768px) {

.card {
  padding: 15px;
}
}


.loader {
  margin: 10px 0;
  --cell-size: 7px;
  --cell-spacing: 1px;
  --cells: 3;
  --total-size: calc(var(--cells) * (var(--cell-size) + 2 * var(--cell-spacing)));
  display: flex;
  flex-wrap: wrap;
  width: var(--total-size);
  height: var(--total-size);
}

.cell {
  flex: 0 0 var(--cell-size);
  margin: var(--cell-spacing);
  background-color: transparent;
  box-sizing: border-box;
  border-radius: 4px;
  animation: 1.5s ripple ease infinite;
}

.cell.d-1 {
  animation-delay: 100ms;
}

.cell.d-2 {
  animation-delay: 200ms;
}

.cell.d-3 {
  animation-delay: 300ms;
}

.cell.d-4 {
  animation-delay: 400ms;
}

.cell:nth-child(1) {
  --cell-color: #7460be;
}

.cell:nth-child(2) {
  --cell-color: #826fc9;
}

.cell:nth-child(3) {
  --cell-color: #7664b4;
}

.cell:nth-child(4) {
  --cell-color: #9b6fdf;
}

.cell:nth-child(5) {
  --cell-color: #a16cde;
}

.cell:nth-child(6) {
  --cell-color: #a76ade;
}

.cell:nth-child(7) {
  --cell-color: #ad67dd;
}

.cell:nth-child(8) {
  --cell-color: #b364dc;
}

.cell:nth-child(9) {
  --cell-color: #b961db;
}


/*Animation*/
@keyframes ripple {
  0% {
    background-color: transparent;
  }

  30% {
    background-color: var(--cell-color);
  }

  60% {
    background-color: transparent;
  }

  100% {
    background-color: transparent;
  }
}
  </style>
  
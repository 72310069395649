<template>
    <div class="section">
        <div class="title">
            <h3>Voice Cloning</h3>
        </div>
        <div class="text">
            Click on the record button and read the short text provided on the screen
        </div>
        <div class="text">
            or click on the upload button and choose a file.
        </div>
        <div class="text">
            Make sure there's no background noise, speak clearly and maintain a consistent volume level.
        </div>
    </div>

</template>

<script>
    export default {
        
    }
</script>

<style scoped>
.section {
    margin: 45px 0;
    width: 100%;
}
.text {
    font-size: 14px;
    font-weight: 100;
}
.title {
    margin-bottom: 10px;
}
</style>
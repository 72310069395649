<template>
    <div class="pills-area">
      <div
        v-for="(option, index) in options"
        :key="index"
        @click="selectOption(option)"
        :class="{ 'pill': true, 'selected': selectedOption === option }">
        {{ option }}
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        // , 'PDF Presentation', 'E-commerce'
        options: ['Video', 'YouTube Comment'],
        selectedOption: null,
      };
    },
    mounted() {
      // Set the first option as the default selected option
      this.selectedOption = this.options[0];
      // Emit the default selected option to the parent component
      this.$emit('selected', this.selectedOption);
    },
    methods: {
      selectOption(option) {
        this.selectedOption = option;
        this.$emit('selected', option);
      },
    },
  };
  </script>
  
  <style scoped>

    .pills-area {
        margin: 10px 0px;
    }

  .pill {
    display: inline-block;
    padding: 7px 12px;
    margin: 4px;
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 10px;
    font-size: 14px;
  }
  
  .selected {
    background-color: #231f20;
    color: #fff;
  }
  </style>
  
<template>
  <div>
      <TopBar :title="pageTitle" ></TopBar>

      <Loading v-if="loading"></Loading>

      <div v-if="!loading && !videos" :class="{'main-wrapper': $store.state.toggle, 'main-wrapper-closed': !$store.state.toggle}">
        <div class="container">
        <div class="card">
          <div class="row">
          </div>
          <div class="row">
            <div class="no-videos">
              <div class="image-no-video">
                <img src="@/assets/logo/180-logo.png" alt="No-videos" />
              </div>
              <div class="small">
                You haven't created any videos yet.
              </div>
            </div>

          </div>
        </div>
      </div>
      </div>

      <div v-if="!loading && videos" :class="{'main-wrapper': $store.state.toggle, 'main-wrapper-closed': !$store.state.toggle}">
        <div class="page-title">
            <h4>All creation</h4>
          </div>
          <router-link
              v-if="videos"
              v-for="(video, index) in videos"
              :key="video.uid"
              :to="{ name: 'video', params: { id: video.video_uid } }"
              active-class="active-link">
              <li class="item-wrapper">
                  <div class="image">
                      <img class="icon" :src="video.video_thumbnail" alt="video"  loading="lazy">
                  </div>
                  <div class="side">
                      <div class="top-row">
                          <div class="name">
                              {{ video.main_clip_bot_name }}
                          </div>
                      </div>
                      <div class="middle-row">
                          <div class="name">
                              {{ video.main_clip_text }}
                          </div>
                      </div>
                      <div class="bottom-row">
                          <div class="info">
                              Status: {{ video.status }} |  Uid: {{ video.video_uid }} | Create at: {{ video.created_date }}
                          </div>
                      </div>
                  </div>
                  <div class="arrow">
                          >
                  </div>
              </li>
          </router-link>
      </div>
  </div>
</template>

<script>
import axios from 'axios';
import TopBar from '@/components/topbar/TopBar.vue';
import Loading from '@/components/Loading.vue';

export default {
components: {
  TopBar,
  Loading
},
data() {
  return {
    loading: false,
    error: '',
    pageTitle: '',
    videos: [],
  };
},
created() {
  this.fetchVideos(); // Corrected method name
},
methods: {
  async fetchVideos() { // Corrected method definition
    this.loading = true;

    try {
      // Get the access token from localStorage
      const accessToken = localStorage.getItem('access_token');

      // Make a GET request to your backend API with the authorization header
      const response = await axios.get('/v1/video-info/', {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      // Assuming your API returns data as a list of keys
      const data = response.data.data.videos;
      this.videos = data;
      this.loading = false; // Set loading to false after successful data fetch
    } catch (error) {
      this.loading = false; // Set loading to false in case of an error
      this.error = 'Error fetching data'; // Set the error message
      console.error('Error fetching:', error);
    }
  },
},
};
</script>


<style scoped>

.item-wrapper {
  width: 100%;
  min-height: 130px;
  border-bottom: 1px solid #e4e7e7;
  display: flex;
  align-items: center;
  padding: 15px;
  justify-content: flex-start;
  gap: 25px;
  border-radius: 5px;
  box-shadow: rgb(184 184 184 / 15%) 0px 4px 7px 0px;
  margin: 10px 0;
}
img.icon {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.image {
    width: 145px;
    height: 115px;
    overflow: hidden;
    border-radius: 10px;
    min-width: 115px;
    min-height: 115px;
}
.top-row, .bottom-row {
  width: 100%;
  display: flex;
}
.side {
  width: 100%;
}
.arrow {
  display: flex;
  justify-content: flex-end;
  width: 15px;
}

a {
  width: 100%;
}
.info {
  font-size: 12px;
  text-align: left;
}
.middle-row {
    font-size: 12px;
    text-align: left;
    overflow: unset;
    margin: 10px 0px;
}
.container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.card {
  background: #f7f7f7;
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 30px;
  flex-direction: column;
}
.row {
  gap: 25px;
  width: 100%;
  display: flex;
  padding: 10px;
}
.no-videos {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 30vh;
}

.image-no-video img {
  width: 80px;
}
</style>
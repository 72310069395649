<template>
    <div>
      <TopBar></TopBar>

      <Loading v-if="loading"></Loading>

        <div v-if="!loading && bots" :class="{'main-wrapper': $store.state.toggle, 'main-wrapper-closed': !$store.state.toggle}">
          <div class="page-title">
            <h4>My avatars</h4>
          </div>
            <router-link
                v-if="bots"
                v-for="(bot, index) in bots"
                :key="bot.uid"
                :to="{ name: 'bot', params: { id: bot.uid } }"
                active-class="active-link">
                <li class="item-wrapper">
                    <div class="image">
                        <img class="icon" :src="bot.thumbnail" alt="bot"  loading="eager">
                    </div>
                    <div class="side">
                        <div class="top-row">
                            <div class="name">
                                {{ bot.name }}
                            </div>
                        </div>
                        <div class="bottom-row">
                            <div class="info">
                                Starts: {{ bot.status }} |  Create at: {{ bot.created_date }}
                            </div>
                        </div>
                    </div>
                    <div class="arrow">
                            >
                    </div>
                </li>
            </router-link>
        </div>
        <div v-if="!loading && !bots" :class="{'main-wrapper': $store.state.toggle, 'main-wrapper-closed': !$store.state.toggle}">
          
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import TopBar from '@/components/topbar/TopBar.vue';
import Loading from '@/components/Loading.vue';

export default {
  components: {
    TopBar,
    Loading
  },
  data() {
    return {
      loading: true,
      error: '',
      pageTitle: '',
      bots: [],
    };
  },
  created() {
    this.fetchBots();
  },
  methods: {
    async fetchBots() {
      this.loading = true;

      try {
        // Get the access token from localStorage
        const accessToken = localStorage.getItem('access_token');

        // Make a GET request to your backend API with the authorization header
        const response = await axios.get('/v1/get-bot', {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });

        // Assuming your API returns data as a list of keys
        const data = response.data.data.bots;
        this.bots = data;

        if (!this.bots || this.bots.length === 0) {
          this.$router.push({ name: 'newBot' });
        }

        this.loading = false; // Set loading to false after successful data fetch
      } catch (error) {
        this.loading = false; // Set loading to false in case of an error
        this.error = 'Error fetching data'; // Set the error message
        console.error('Error fetching:', error);
      }
    },
  },
};
</script>


<style scoped>
.main-wrapper {
  padding: 50px;
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden;
  margin-left: 300px;
  align-items: center;
  position: relative;
}
.main-wrapper > * {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
}
.item-wrapper {
  width: 100%;
    height: 80px;
    border-bottom: 1px solid #e4e7e7;
    display: flex;
    align-items: center;
    padding: 15px;
    justify-content: flex-start;
    gap: 25px;
    box-shadow: rgb(184 184 184 / 15%) 0px 4px 7px 0px;
    border-radius: 5px;
    margin: 10px 0;
}
img.icon {
    width: 50px;
}

.image {
    box-shadow: 3px 4px 7px 0 hsl(0deg 0% 72% / 26%);
    border: 0.1px solid #cfd3d347;
    width: 60px;
    height: 60px;
    min-width: 60px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    border-radius: 62px;
    overflow: hidden;
    position: relative;
    align-items: flex-end;
    background: #efefef99;
    transition: background .3s ease;
}

.top-row, .bottom-row {
    width: 100%;
    display: flex;
}
.side {
    width: 100%;
}
.arrow {
    width: 15px;
    display: flex;
    justify-content: flex-end;
}

a {
    width: 100%;
}
.info {
    font-size: 12px;
    text-align: left;
}

</style>
<template>
  <div class="page">
  <TopBar></TopBar>
  <div :class="{'main-wrapper-bot': $store.state.toggle, 'main-wrapper-bot-closed': !$store.state.toggle}">  
    <Delete item-name="avatar" :deletedText=deletedText v-if="showDeleteDialog" @confirm="deleteBot" @cancel="closeDeleteDialog" /> 

    <div class="loading" v-if="loading"><div class="spinner"></div></div>
    <div v-else>
      <div class="container">
        <div class="card">

          <div class="row">
            <div class="image-wrap">
                <img v-if="bot" :src="bot.thumbnail" alt="Thumbnail" loading="lazy">
            </div>
            <div class="info-wrap">

              <div class="top">
                <div class="bot-name">
                  <h2 v-if="bot">{{ bot.name }}</h2>
                </div>
                <div class="status">
                  <h5 v-if="bot">Status:</h5>
                  <div v-if="bot.is_ready" class="ready">
                    <div class="text">
                      Ready
                    </div>
                    <div class="g-dot"></div>
                  </div>
                  <div class="not-ready" v-else>
                    <div class="text">
                      In process
                    </div>
                      <!-- <div class="spinner-status"></div> -->
                  </div>
                </div>
              </div>

              <div class="bottom">
                <div class="bottom-data id">
                  <p v-if="bot">Uid: {{ bot.uid }}</p>
                </div>
                <!-- <div class="bottom-data">
                  <p v-if="bot">Created: {{ bot.created_date }}</p>
                </div> -->
            <div class="buttons-wrap">
              <!-- <button class="btn" type="submit">Edit details</button> -->
              <button class="btn" @click="openDeleteDialog">Delete</button>
            </div>
              </div>
              
            </div>
          </div>



        </div>

        <Voices :botInfo="bot"></Voices>

      </div>


      <Videos :botInfo="bot"></Videos>

    </div>
  </div> 
</div>
</template>

<script>
import axios from 'axios';
import TopBar from '@/components/topbar/TopBar.vue';
import Delete from '@/components/Delete.vue';
import Videos from '@/components/bot/Videos.vue';
import Voices from '@/components/bot/Voices.vue';

export default {
  components: {
    Delete,
    Voices,
    Videos,
    TopBar
    },
  data() {
    return {
      bot: [],
      loading: true,
      error: null,
      checkBotStatusInterval: null,
      showDeleteDialog: false,
      deletedText: 'All videos associated with this avatar will be deleted.'
    };
  },
  created() {
    this.fetchBotData();
  },
  methods: {
    fetchBotData() {
      // Retrieve bot UID from the route params
      const botId = this.$route.params.id;

      // Get the access token from localStorage
      const accessToken = localStorage.getItem('access_token');

      const checkBotStatus = () => {
        axios.get(`/v1/get-bot/${botId}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        })
          .then(response => {
            const fetchedBot = response.data.data.bot[0];

            // Update component state with the fetched data
            this.bot = fetchedBot;

            // Check if the bot is ready
            if (fetchedBot.is_ready) {
              // Bot is ready, stop the interval
              clearInterval(this.checkBotStatusInterval);
              this.checkBotStatusInterval = null;
            }
          })
          .catch(error => {
            this.$router.push({ name: 'myBots' });
          })
          .finally(() => {
            // Update loading state to false
            this.loading = false;
          });
      };

      // Initial fetch
      checkBotStatus();

      // Set up an interval to check bot status every 10 seconds
      this.checkBotStatusInterval = setInterval(checkBotStatus, 20000);
    },
    openDeleteDialog() {
      this.showDeleteDialog = true;
    },

    closeDeleteDialog() {
      // Close the delete dialog
      this.showDeleteDialog = false;
    },
    deleteBot() {
      // Delete bot logic
      this.showDeleteDialog = false;
      this.loading = true;
      const botId = this.bot.uid;
      const accessToken = localStorage.getItem('access_token');
      const userData = this.$store.state.auth.userData;

      axios.delete(`/v1/bot/${botId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      })
        .then(() => {
          // Bot deleted successfully, navigate to another page or perform any other actions
          console.log('Bot deleted successfully');
        })
        .catch(error => {
          console.error('Error deleting bot:', error);
          this.loading = false;
        })
        .finally(() => {
          if (userData && userData.bots === 1) {
            this.$router.push({ name: 'main' });
          } else {
            this.$router.push({ name: 'myBots' });
          }
        });
    },
  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed
    if (this.checkBotStatusInterval) {
      clearInterval(this.checkBotStatusInterval);
    }
  },
  beforeRouteLeave(to, from, next) {
    // Clear the interval when leaving the current route
    if (this.checkBotStatusInterval) {
      clearInterval(this.checkBotStatusInterval);
    }
    next();
  },
};
</script>


<style scoped>

.top, .bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.card {
  background: #f7f7f7;
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 30px;
  flex-direction: column;
  box-shadow: rgb(184 184 184 / 15%) 3px 4px 7px 0px;
}
.buttons-wrap {
    gap: 10px;
    width: 100%;
    display: flex;
    margin: 5px 0px;
}

.info-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    text-align: left;
}

.status {
    display: flex;
    align-items: center;
    gap: 5px
}

.row {
  gap: 20px;
  width: 100%;
  display: flex;
  padding: 10px;
}
.text {
    font-size: 13px;
}

.status h5 {
  font-size: 13px;
}

.ready, .not-ready {
  font-size: 13px;
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: flex-start;
}


.loading {
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 130px);
    align-items: center;
}
.g-dot {
  background-color: green;
  width: 5px;
  height: 5px;
  border-radius: 10px;
}
.image-wrap {
  width: 180px;
  height: 180px;
  min-width: 180px;
  min-height: 180px;
  border: 5px solid #fff;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  background: rgb(239 239 239);
  border-radius: 190px;
  overflow: hidden;
  box-shadow: rgb(184 184 184 / 15%) 3px 4px 7px 0px;
}
.image-wrap img {
    width: 150px;
    cursor: pointer;
}
.bottom-data {
    font-size: 14px;
}
/* Add media query for responsive design */
@media (max-width: 1400px) {
  .container {
    grid-template-columns: 1fr;
  }
}

.id p {
  user-select: text;
}
.btn {
  max-width: 100px;
  border-radius: 10px;
  height: 37px;
}


@media only screen and (max-width: 768px) {

.row {
  flex-direction: column;
  align-items: center;
}

}
</style>

import { createStore } from 'vuex'
import authModule from './authModule';
import sidebarVideosModule from './videosModule';

export default createStore({
  state: {
    toggle: window.innerWidth > 768,
    videoData: {
      selectedFile: null,
      name: '',
      gender: '',
      checkbox: false,
      thumbnail_id: '',
      uid: '',
      previewImage: '',
      video_file_extention: '',
    },
    voiceData: {
      selectedFileAudio: null,
      isRecording: false,
      checkbox: false,
      audioBlob: null,
      audio_file_extention: null
    },
    publicData: {
      public: false,
      age: '',
      gender: '',
      accent: '',
    },
    settingsData: {
      bots: [],
      selected_bot: '',
      selected_bot_tb: '',
      selected_bot_is_ready: false,
      position: 'bottom-right',
      remove_bg: true,
      fade_in: true,
      fade_out: true,
      background_volume: 0.1,
      reusable: false,
      clip_name: '',
    },
  },
  getters: {
  },
  mutations: {
    toggleSidebar(state) {
      state.toggle = !state.toggle;
    },
    updateVideoData(state, payload) {
      state.videoData = { ...state.videoData, ...payload };
    },
    updateVoiceData(state, payload) {
      state.voiceData = { ...state.voiceData, ...payload };
    },
    updatePublicData(state, payload) {
      state.publicData = { ...state.publicData, ...payload };
    },
    updateSettingsData(state, payload) {
      state.settingsData = { ...state.settingsData, ...payload };
    },
  },
  actions: {
    updateVideoData({ commit }, payload) {
      commit('updateVideoData', payload);
    },
    updateVoiceData({ commit }, payload) {
      commit('updateVoiceData', payload);
    },
    updatePublicData({ commit }, payload) {
      commit('updatePublicData', payload);
    },
    updateSettingsData({ commit }, payload) {
      commit('updateSettingsData', payload);
    },
  },
  modules: {
    auth: authModule,
    video: sidebarVideosModule
  }
})



<template>
    <div>
        <TopBar></TopBar>

        <div @click="delError" :class="{'main-wrapper': $store.state.toggle, 'main-wrapper-closed': !$store.state.toggle}">
          
        <div v-if="!submit" class="card-wrap">
            <div class="stage">

                <div class="stage-number" :class="{ active: currentStep === 1 }">1</div>
                <div class="stage-item" :class="{ active: currentStep === 1 }">Guidelines</div>

                <div class="stage-arrow">></div>

                <div class="stage-number" :class="{ active: currentStep === 2 }">2</div>
                <div class="stage-item" :class="{ active: currentStep === 2 }">Video</div>

                <div class="stage-arrow">></div>

                <div class="stage-number" :class="{ active: currentStep === 3 }">3</div>
                <div class="stage-item" :class="{ active: currentStep === 3 }">Voice</div>
            </div>

    

            <StepTwo v-if="currentStep === 1"></StepTwo>
            <div v-if="currentStep === 1" class="buttons">
                <button class="btn" @click="nextStep">Upload video</button>
            </div>

            <StepThree v-if="currentStep === 2"></StepThree>
            <div v-if="currentStep === 2" class="buttons">
                <button class="btn" @click="prevStep">Previous</button>
                <button class="btn" @click="nextStep" :disabled="!$store.state.videoData.checkbox || !$store.state.videoData.name || !$store.state.videoData.selectedFile || !$store.state.videoData.gender">Continue</button>
            </div>

            <StepFour v-if="currentStep === 3"></StepFour>
            <div class="error">
            {{ error }}
            </div>
            <div v-if="currentStep === 3" class="buttons">
                <button class="btn" @click="prevStep">Previous</button>
                <button class="btn" @click="submitForm" :disabled="!$store.state.voiceData.checkbox || !$store.state.voiceData.selectedFileAudio  && !$store.state.voiceData.audioBlob">Create</button>
            </div>

        </div>

        <div v-if="submit" class="card-wrap loading">
            <div  class="spinner"></div>
            <div class="loading-text">
                {{ message }}
            </div>
        </div>

    </div>  
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import TopBar from '@/components/topbar/TopBar.vue';
  import StepOne from '@/components/createbot/StepOne.vue';
  import StepTwo from '@/components/createbot/StepTwo.vue';
  import StepThree from '@/components/createbot/StepThree.vue';
  import StepFour from '@/components/createbot/StepFour.vue';

  export default {
    components: {
        TopBar,
        StepOne,
        StepTwo,
        StepThree,
        StepFour
    },
    data() {
      return {
        currentStep: 1,
        isVideoStepComplete: false,
        submit: false,
        error: '',
        message: 'Starting process',
        video_etag: null,
        video_ext: null,
        audio_etag: null,
        audio_ext: null,
        audio: null,
        video: null,
        video_file_key: null,
        audio_file_key: null
      };
    },
    methods: {
      nextStep() {
        if (this.currentStep < 3) {
          this.currentStep++;
        }
      },
      prevStep() {
        if (this.currentStep > 1) {
          this.currentStep--;
        }
      },
      delError() {
        this.error = '';
      },
      emitValuesToParent() {
        this.$store.dispatch('updateVideoData', {
            selectedFile: null,
            name: '',
            gender: '',
            checkbox: false,
            thumbnail_id: '',
            previewImage: '',
        });
        this.$store.dispatch('updateVoiceData', {
          selectedFileAudio: null,
          checkbox: false,
          isRecording: false,
          audioBlob: null,
        });
        },
        async submitForm() {
        this.submit = true;

        if (this.$store.state.videoData.selectedFile) {
          this.video = this.$store.state.videoData.selectedFile;
        }

        if (this.$store.state.voiceData.selectedFileAudio) {
          this.audio = this.$store.state.voiceData.selectedFileAudio;
        } else if (this.$store.state.voiceData.audioBlob) {
          const audioFile = await fetch(this.$store.state.voiceData.audioBlob).then((res) => res.blob());
          this.audio = audioFile;
        } else {
          console.warn('No audio file selected or generated.');
        }

        const accessToken = localStorage.getItem('access_token');

        try {
          const file_name_video = 'avatar-video.' + this.$store.state.videoData.video_file_extention;
          const response = await axios.post('/upload-url-avatar', { fileName: file_name_video, uid: this.$store.state.videoData.uid }, {
            headers: { Authorization: `Bearer ${accessToken}` },
          });
          const url = response.data.url;
          console.log(url)
          this.video_file_key = response.data.file_key;

          this.message = 'Uploading video';

          const formData = new FormData();
          formData.append('file', this.video);

          const uploadResponse = await axios.put(url, this.video, {
            headers: { 'Content-Type': 'binary/octet-stream' },
          });

          this.video_etag = uploadResponse.headers['etag'];
          this.video_ext = this.$store.state.videoData.video_file_extention;

          console.log('Upload Response:', uploadResponse.data);
          console.log('Video uploaded successfully!');
        } catch (error) {
          console.error('Error uploading video:', error);
            this.message = '';
            this.submit = false;
        }

        try {
          this.message = 'Uploading voice';
          const file_name_audio = 'avatar-audio.' + this.$store.state.voiceData.audio_file_extention;
          const response = await axios.post('/upload-url-avatar', { fileName: file_name_audio, uid: this.$store.state.videoData.uid }, {
            headers: { Authorization: `Bearer ${accessToken}` },
          });
          const url = response.data.url;
          this.audio_file_key = response.data.file_key;

          const formData = new FormData();
          formData.append('file', this.audio);

          const uploadResponse = await axios.put(url, this.audio, {
            headers: { 'Content-Type': 'binary/octet-stream' },
          });

          this.audio_etag = uploadResponse.headers['etag'];
          this.audio_ext = this.$store.state.voiceData.audio_file_extention;

          console.log('Upload Response:', uploadResponse.data);
          console.log('Audio uploaded successfully!');
        } catch (error) {
          console.error('Error uploading audio:', error);
          this.message = '';
          this.submit = false;
        }

        this.message = 'Creating avatar'

        const botFormData = new FormData();
        botFormData.append('video_file_key', this.video_file_key);
        botFormData.append('audio_file_key', this.audio_file_key);
        botFormData.append('video_etag', this.video_etag);
        botFormData.append('video_ext', this.video_ext);
        botFormData.append('audio_etag', this.audio_etag);
        botFormData.append('audio_ext', this.audio_ext);
        botFormData.append('name', this.$store.state.videoData.name);
        botFormData.append('public', this.$store.state.publicData.public);
        botFormData.append('age', this.$store.state.publicData.age);
        botFormData.append('gender', this.$store.state.videoData.gender);
        botFormData.append('accent', this.$store.state.publicData.accent);
        botFormData.append('thumbnail_id', this.$store.state.videoData.thumbnail_id);
        botFormData.append('uid', this.$store.state.videoData.uid);

        axios.post('/v1/bot', botFormData, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data',
          },
        })
          .then((response) => {
            const botId = response.data.data.bot_id;
            this.emitValuesToParent();
            this.$router.push({ name: 'bot', params: { id: botId } });
          })
          .catch((error) => {
            this.error = error.response.data.message;
            this.message = ''
            this.submit = false;
          });
      }
    },
  };
  </script>
  
  <style scoped>
  .main-wrapper {
    padding: 50px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-x: hidden;
    margin-left: 300px;
}
  .buttons {
    display: flex;
    gap: 5px;
    width: 100%;
    justify-content: center;
  }
  
.btn {
    max-width: 160px;
  }
  span.stage-arrow {
    margin-top: 1px;
}
  .card-wrap {
    background: #f7f7f7;
    width: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    flex-direction: column;
    min-height: calc(100vh - 130px);
    box-shadow: rgb(184 184 184 / 24%) 0px 3px 7px 0px;
  }

  .card-wrap.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
  
  .stage {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-bottom: 15px;
    align-items: center;
  }
  
  .stage span {
    font-weight: bold;
    cursor: pointer;
  }
  
  .stage-arrow div.active {
    color: #4b4b4b;
  }

  .stage-item.active {
    color: #4b4b4b;
  }

  .stage-number {
    background: #dfdfdf;
    color: white;
    display: flex;
    width: 18px;
    height: 18px;
    border-radius: 50px;
    font-size: 12px;
    justify-content: center;
    align-items: center;
}
.stage-number.active {
    background: #4b4b4b;
}
.btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
.stage-item {
    font-size: 14px;
    color: #adadad;
}
.error {
    color: #e01a1a;
    margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
  .card-wrap {
    background: #ffffff;
    box-shadow: none;
    padding: 30px 0px;
  }
}
  </style>
<template>
  <div>

<Loading v-if="!$store.state.auth.userData"></Loading>

<TopBar v-if="$store.state.auth.userData"></TopBar>

<transition name="fade">
  <NoBots @cancel="closeDialog" v-if="showNoBots"></NoBots>
</transition>

<Intro v-if="$store.state.auth.userData && $store.state.auth.userData.bots == 0"></Intro>

<div :class="{'main-wrapper': $store.state.toggle, 'main-wrapper-closed': !$store.state.toggle}">

<div class="logo-wrapper">
    <img src="@/assets/logo/300-logo-hor.png" alt="logo" />
</div>

<Options @selected="handleSelected"></Options>

<CreateVideo v-if="selectedOption == 'Video'" @inputAreaClick="clickInput"></CreateVideo>

<CreateYouTube v-if="selectedOption == 'YouTube Comment'" @inputAreaClick="clickInput"></CreateYouTube>


</div>
</div>
</template>

<script>
import TopBar from '@/components/topbar/TopBar.vue';
import Loading from '@/components/Loading.vue';
import Intro from '@/components/main/Intro.vue';
import UrlPreview from '@/components/main/UrlPreview.vue';
import Bots from '@/components/main/Bots.vue';
import NoBots from '@/components/NoBots.vue';
import CreateVideo from '@/components/main/CreateVideo.vue';
import CreateYouTube from '@/components/main/CreateYouTube.vue';
import Options from '@/components/main/Options.vue';

export default {
  components: {
    Loading,
    TopBar,
    Intro,
    Bots,
    UrlPreview,
    NoBots,
    CreateVideo,
    Options,
    CreateYouTube
    },
    data() {
    return {
      selectedOption: null,
      showNoBots: false,
      error: '',
    };
  },
  methods: {
    handleSelected(option) {
      this.selectedOption = option;
    },
    closeDialog() {
      this.showNoBots = false;
    },
    clickInput() {
      if (this.$store.state.auth.userData.bots == 0) {
        this.showNoBots = true
        return;
      }
      this.error = '';
    },
  },
}
</script>

<style scoped>
.logo-wrapper {
    margin-bottom: 30px;
}

.logo-wrapper img {
    width: 200px;
}

@media only screen and (max-width: 768px) {

  .logo-wrapper img {
    width: 140px;
}

}
</style>
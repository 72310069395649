<template>
  <div>
    <div class="frame">
      <h2>Recording Guidelines</h2>
      <!-- <div class="video-container">
        <video autoplay muted loop poster="/video/sample1.png" preload="metadata">
          <source src="/video/sample1.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div> -->

      <div class="text-container">
        <VideoGuidelines></VideoGuidelines>
      </div>
    </div>
  </div>
</template>

  
  <script>
  import VideoGuidelines from '@/components/createbot/VideoGuidelines.vue';
  export default {
    components: {
      VideoGuidelines,
    },
  };
  </script>
  
  <style scoped>

  .frame {
    padding: 20px;
  }
  .video-container {
    width: 100%;
  }
  
  video {
    width: 70%; /* Set the width to 100% */
    max-width: 800px;
    min-width: 300px; /* Add a minimum width to prevent it from becoming too small */
    border-radius: 10px;
  }

  .text-container {
    font-size: 15px;
    font-weight: 600;
    margin: 10px 0;
  }

  </style>
  
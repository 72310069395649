<template>
    <div>
      <TopBar></TopBar>
    
      <div :class="{'main-wrapper': $store.state.toggle, 'main-wrapper-closed': !$store.state.toggle}">
  
        <section class="pricing-tables content-area">
          <div class="container">
            <div class="row">
              <div class="col">
                <div class="pricing featured">
                  <div class="listing-badges">
                    <span class="featured">Featured</span>
                  </div>
                  <div class="price-header price-header-2">
                    <div class="title">Pay as you go</div>
                    <div class="title-small">Credits available for use forever</div>
                    <div class="price-wrap">
                        <div class="price">USD {{ selectedPackage.pricePerCredit }}</div>
                        <div class="price-small">/</div>
                        <div class="price-small">credit</div>
                    </div>
                  </div>
  
                  <div class="content">
                    <ul>
                      <li v-for="pkg in packages" :key="pkg.id">
                        <input type="radio" :value="pkg.id" v-model="selectedPackageId"> <div class="tba">{{ pkg.credits }} credits </div> | <div class="tba">{{ pkg.price }}</div> | <div class="tba">{{ pkg.pricePerCredit }}/credit</div>
                      </li>
                    </ul>
                    <div class="button-wrap">
                    <button v-if="!loading" class="btn" @click="submitForm">Purchase now</button>
                    <button v-if="loading" class="btn" disabled><div class="spinner"></div></button>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
  
      </div>
    </div>
  </template>
  
  <script>
import axios from 'axios';
import TopBar from '@/components/topbar/TopBar.vue';
import Loading from '@/components/Loading.vue';

export default {
  components: {
    TopBar,
    Loading
  },
  data() {
    return {
      loading: false,
      error: '',
      selectedPackageId: 2,
      selectedPackage: {},
      packages: [
        { id: 1, credits: 2000, price: '$10.9', pricePerCredit: '$0.0054', productId: 'price_1OjHrEDT9lMNe8UxUn8sYV9r'},
        { id: 2, credits: 5000, price: '$21.9', pricePerCredit: '$0.0045', productId: 'price_1OjHsdDT9lMNe8Uxakkh69ov'},
        { id: 3, credits: 10000, price: '$42.9', pricePerCredit: '$0.0039', productId: 'price_1OjHtMDT9lMNe8UxxEsjATN8'}
      ]
    };
  },
  watch: {
    selectedPackageId(newValue, oldValue) {
      this.selectedPackage = this.packages.find(pkg => pkg.id === parseInt(newValue));
    }
  },
  mounted() {
    // Initialize selected package
    this.selectedPackage = this.packages.find(pkg => pkg.id === this.selectedPackageId);
  },
  methods: {
    async submitForm() {
        this.loading = true
  try {
    const accessToken = localStorage.getItem('access_token');
    const response = await axios.post('/v1/checkout-session', {
      packageId: this.selectedPackage.id,
      credits: this.selectedPackage.credits,
      price: this.selectedPackage.price,
      pricePerCredit: this.selectedPackage.pricePerCredit,
      productId: this.selectedPackage.productId
    }, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    });
    
    // Handle the response
    const checkoutSessionUrl = response.data.checkout_session_url;
    window.location.href = checkoutSessionUrl; // Redirect to the checkout session URL
  } catch (error) {
    this.loading = false
    console.error('Error:', error);
  }
}
  }
}
</script>
  

<style scoped>
.main-wrapper {
  padding: 50px;
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden;
  margin-left: 300px;
  align-items: center;
  position: relative;
}
.main-wrapper > * {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
}



.pricing {
    text-align: center;
    margin-bottom: 30px;
    position: relative;
    z-index: 9;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}

.pricing .price-header {
    width: 100%;
    background: #fff;
    padding: 35px 50px;
    border-radius: 20px;
}

.pricing .price-header .title {
    font-size: 25px;
    font-weight: 600;
    color: #37404d;
}


.pricing .content {
    padding: 20px 20px 30px;
    background: #f9f9f9;
}

.pricing .content ul {
    margin: 0 0 20px;
}

.pricing .content ul li {
    padding: 8px 0;
    font-size: 16px;
    color: #8c8ffd;
    gap: 15px;
}

.pricing.featured .listing-badges {
    position: absolute;
    top: 0;
    z-index: 999;
    right: 0;
    width: 100%;
    display: block;
    font-size: 15px;
    padding: 0;
    overflow: hidden;
    height: 100px;
}

.pricing.featured .featured {
    float: left;
    transform: rotate(-45deg);
    left: -67px;
    top: 17px;
    position: relative;
    text-align: center;
    width: 200px;
    font-size: 13px;
    margin: 0;
    padding: 7px 10px;
    font-weight: 500;
    color: #fff;
}
.pricing.featured .featured {
    background: linear-gradient(120deg, #9b51e0, #0693E3);
}

.col {
    min-width: 320px;
}

.price-wrap {
    font-size: 35px;
    font-weight: 300;
    margin: 0;
    color: #3d7ae2;
    margin-top: 40px;
    display: flex;
    align-items: baseline;
    gap: 3px;
    justify-content: center;
}

.price-small {
    font-size: 14px;
}

.title-small {
    font-size: 14px;
}

li {
    display: flex;
    flex-direction: row;
    align-items: center;
}

input[type="radio"] {
    width: 20px;
}

.button-wrap {
    padding: 20px;
}

.price {
    font-weight: 600;
}

button.btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner {
    width: 20px;
    height: 20px;
    border-top: 2px solid #ffffff;
}
</style>
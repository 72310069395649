<template>
  <div>
    <div class="frame">

      <div class="text-container">
        Create Your Virtual Self in a Few Simple Steps!
      </div>
      
      <div class="video-container">
        <video ref="myVideo" poster="/video/sample0.png">
          <source src="/video/sample0.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
        <div class="custom-controls">
          <button class="btn" v-if="!is_playing" @click="playPause"><img src="@/assets/icons/play.svg" alt="play" /></button>
          <button class="btn" v-if="is_playing" @click="playPause"><img src="@/assets/icons/stop.svg" alt="stop" /></button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      is_playing: false
    }
  },
  methods: {
    playPause() {
      const video = this.$refs.myVideo;
      if (video.paused) {
        this.is_playing = true
        video.play();
      } else {
        this.is_playing = false
        video.pause();
      }
    },
  },
};
</script>
  
  <style scoped>

  .frame {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .video-container {
    width: 100%;
  }
  
  video {
    width: 100%;
    max-width: 800px;
    min-width: 300px;
    border-radius: 10px;
  }

  .text-container {
    font-size: 15px;
    font-weight: 600;
    margin: 10px 0;
  }

  .btn {
    background: #eeeeee;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dfdfdf;
  }
  .btn img {
    width: 17px;
  }

  .custom-controls {
    display: flex;
    justify-content: center;
}
  </style>
  
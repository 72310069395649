import axios from 'axios';

const state = {
  userVideos: [],
  loading_vids: false
};

const getters = {
    userVideos: state => state.userVideos,
};

const mutations = {
  SET_USER_VIDS(state, userVideos) {
    state.userVideos = userVideos;
  },
  SET_LOADING_VIDS(state, loading_vids) {
    state.loading_vids = loading_vids;
  },
};

const actions = {
  async getUserVids({ commit }) {

    commit('SET_LOADING_VIDS', true);

    const accessToken = localStorage.getItem('access_token');

    try {
      const response = await axios.get('/v1/video-info/', {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      if (response.data && response.data.data) {
        // Set user videos
        commit('SET_USER_VIDS', response.data.data.videos);
      } 
    } catch (error) {
      console.log('Error fetching user videos');
    } finally {
      commit('SET_LOADING_VIDS', false);
    }
  },
  
  startLoading({ commit }) {
    commit('SET_LOADING_VIDS', true);
  },
  stopLoading({ commit }) {
    commit('SET_LOADING_VIDS', false);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

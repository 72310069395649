<template>
    <div>
        <div class="card">
          <div v-if="!botInfo.is_ready" class="row load">
            <div class="loading">
                <div class="spinner"></div>
                Creating voice
            </div>
          </div>

            <div v-if="botInfo.is_ready" class="row">
                <div class="title">
                    <h3>{{ botInfo.name }}'s Voice</h3>
                </div>
            </div>

            <div v-if="botInfo.is_ready" class="row load">
                <div class="loading">
                    <audio ref="audioPlayer" controls>
                      <source :src="botInfo.voice_url" type="audio/wav">
                      Your browser does not support the audio tag.
                    </audio>
                    <router-link :to="{ name: 'voice', params: { id: botInfo.uid } }">
                      <button class="btn" type="button">Replace voice</button>
                    </router-link>
                </div>
            </div>


        </div>
      </div>
</template>

<script>
export default {
  props: {
    botInfo: {
      type: Object, 
      required: true,
    },
  },
};
</script>

<style scoped>


.card {
  background: #f7f7f7;
  width: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 30px;
  height: 100%;
  justify-content: flex-start;
  flex-direction: column;
  box-shadow: rgb(184 184 184 / 15%) 3px 4px 7px 0px;
}
.row {
  gap: 25px;
  width: 100%;
  display: flex;
  padding: 10px;
}

.no-videos {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.image img {
  width: 80px;
}

.head {
  font-size: 17px;
  font-weight: 600;
}
.small {
  font-size: 14px;
}

.loading {
    gap: 5px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.row.load .loading {
  align-items: center;
}

.load {
    height: 100%;
}

.btn {
  max-width: 170px;
  border-radius: 10px;
  height: 37px;
}

audio {
    width: 100%;
    margin: 10px 0px;
    margin-bottom: 20px;
}

.row.title {
    max-width: 100px;
}

.spinner {
  width: 30px;
  height: 30px;
}
</style>
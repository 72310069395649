<template>
  <div>
    <div :class="{'main-wrapper': $store.state.toggle, 'main-wrapper-closed': !$store.state.toggle}">

      <div class="profile-wrap">
        <div class="profile-img">

        </div>
        
      </div>


      <div class="tabs">
        <button @click="changeTab(1)" :class="{ active: currentTab === 1 }">Saved</button>
        <button @click="changeTab(2)" :class="{ active: currentTab === 2 }">Tab 2</button>
        <button @click="changeTab(3)" :class="{ active: currentTab === 3 }">Tab 3</button>
      </div>

      <div class="tab-content">
        <div v-if="currentTab === 1"> <!-- Content for Tab 1 -->
          <h2>Tab 1 Content</h2>
          <p>This is the content for Tab 1.</p>
        </div>

        <div v-if="currentTab === 2"> <!-- Content for Tab 2 -->
          <h2>Tab 2 Content</h2>
          <p>This is the content for Tab 2.</p>
        </div>

        <div v-if="currentTab === 3"> <!-- Content for Tab 3 -->
          <h2>Tab 3 Content</h2>
          <p>This is the content for Tab 3.</p>
        </div>
      </div>
    </div>

  </div>
</template>







<script>
export default {
data() {
  return {
    currentTab: 1,
  };
},
methods: {
  changeTab(tabNumber) {
    this.currentTab = tabNumber;
  },
},
};
</script>

<style scoped>

.main-wrapper {
  padding: 50px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  margin-left: 300px;
}
.main-wrapper > * {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
}

.tabs {
display: flex;
}

.tabs button {
padding: 10px;
margin: 5px;
cursor: pointer;
border: 1px solid #ccc;
background-color: #f0f0f0;
}

.tabs button.active {
background-color: #ddd;
}

.tab-content {
margin-top: 20px;
}
</style>

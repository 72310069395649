<template>
    <div>
        <section class="creation">

          <div v-if="(!videos || videos.length === 0) && $store.state.auth.userData.bots === 0" class="no-videos">
              <div class="head">
                It's empty here
              </div>
              <div class="small">
                Start things off with creating your first avatar.
              </div>
              <div class="button">
                <button @click="toCreateBot" class="btn" type="submit">Create Avatar</button>
              </div>
            </div>

            <div v-if="(!videos || videos.length === 0) && $store.state.auth.userData.bots > 0" class="no-videos">
              <div class="head">
                It's empty here
              </div>
              <div class="small">
                You haven't created any videos yet.
              </div>
            </div>

            <router-link
              v-if="videos"
              v-for="(video, index) in videos.slice(0, 5)"
              :key="video.video_uid"
              @click="toggleSidebarMobile"
              :to="{ name: 'video', params: { id: video.video_uid } }"
              active-class="active-link"
            >
              <li class="history-wrapper"
                  @mouseout="updateImage(index, video.video_thumbnail)"
              >
                <a
                  href=""
                  class="history"
                >
                  <div class="bot-image">
                    <img class="icon" :src="videoImages[index]" alt="Logo" loading="lazy">
                  </div>
                  <div class="bot-info">
                    <div class="top">
                      <div class="name">
                        {{ video.main_clip_bot_name }}
                      </div>
                      <div class="date">
                        {{ formatDate(video.created_date) }} >
                      </div>
                    </div>
                    <div class="bottom">
                      <div class="history-text">
                        {{ video.main_clip_text }}
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            </router-link>
        </section>

    </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      videos: [],
      accessToken: localStorage.getItem('access_token'),
      videoImages: [],
    };
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      const month = date.toLocaleString('default', { month: 'short' });
      const day = date.getDate();
      return `${month} ${day}`;
    },
    updateImage(index, src) {
      // Update the image source for the specific item in the array
      this.videoImages[index] = src;
    },
    initializeVideoImages() {
      // Initialize the videoImages array with default image sources
      if (this.videos && this.videos.length > 0) {
        this.videoImages = this.videos.slice(0, 5).map(video => video.video_thumbnail);
      }
    },
    toCreateBot() {
      this.$router.push({ name: 'newBot' });
      if (window.innerWidth <= 768) {
            this.$store.commit('toggleSidebar', false);
      }
    },
    toggleSidebarMobile() {
        if (window.innerWidth <= 768) {
            this.$store.commit('toggleSidebar', false);
        }
    },

  },
  mounted() {
    this.$store.dispatch('getUserVids');
  },
  watch: {
    '$store.state.video.userVideos': {
      handler(newVal) {
        this.videos = newVal || []; // Ensure that this.videos is an array
        this.initializeVideoImages();
      },
      deep: true,
    },
  },
};
</script>


<style scoped>
section.creation {
    min-height: 380px;
}
section.top {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 7px;
    grid-gap: 5px;
    gap: 5px;
    margin: 0;
}

.history-wrapper{
    padding: 15px;
    border-top: 1px solid #e4e7e7;
    border-bottom: 1px solid #e4e7e7;
    transition: background 0.3s ease;
}
.history-wrapper:hover{
    background: #f7f7f7 ;
}
.history {
    display: flex;
    gap: 10px;
    align-items: center;
}
.history-wrapper img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.history-text {
    max-width: 210px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.bot-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
}
.top {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
}
.bottom {
    display: flex;
    font-size: 14px;
    font-weight: 400;
}
.bot-image {
    display: flex;
    width: 50px;
    height: 40px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 5px;
}
.no-videos {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 380px;
}
.active-link {
  background-color: #ff0000;
}

.image img {
  width: 80px;
}

.head {
  font-size: 17px;
  font-weight: 600;
}
.small {
    padding: 7px 47px;
    font-size: 14px;
}

.button {
  margin: 15px 0;
}
.no-videos {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 30vh;
}

.btn {
  height: 35px;
  padding-left: 15px;
  padding-right: 15px;
}
</style>
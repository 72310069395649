<template>

  <div @click="delError" class="frame" :class="{ 'upload-area': !previewImage }">
    <div
      class="card-wrap-empty"
      v-if="!previewImage"
      @click="openFileDialog"
      @dragover.prevent="handleDragOver"
      @dragleave.prevent="handleDragLeave"
      @drop.prevent="handleDrop"
      ref="dropArea"
    >
      <div v-if="!generating_tb">
        <div class="text-exp-header">
          Drop Video File Here
        </div>
        <div class="text-exp">
          Or click to browse
        </div>
      </div>
      <div v-if="generating_tb" class="spinner-wrap">
        <div class="spinner"></div>
        <div class="text">
          Processing video
        </div>
      </div>
    </div>

    <div v-if="previewImage" class="card-wrap">
      <div v-if="!generating_tb && previewImage" class="upload-container">
        <img :src="previewImage" alt="Upload" class="upload-image" @click="openFileDialog" />
      </div>

      <div v-if="previewImage" class="form-container">
        <div class="row">
          <div class="input-label">
            Name
          </div>
          <input type="text" v-model="name" @input="emitChanged" placeholder="" />
        </div>

        <div class="select-wrapper">
          <select class="select" v-model="gender" id="selectGender" @change="emitChanged">
            <option value="" disabled selected hidden>Select Gender</option>
            <option value="Female">Female</option>
            <option value="Male">Male</option>
            <option value="Non-binary">Non-binary</option>
          </select>
        </div>

        <div class="checkbox-wrap">
          <input v-model="checkbox" type="checkbox" name="ownershipConfirmation" id="ownershipConfirmation" @change="emitChanged" />
          I hereby confirm that I am the rightful owner of the video content I am providing. I affirm
          that I possess all necessary rights, permissions, and licenses required to authorize this material.
        </div>
      </div>
    </div>
  </div>

  <div class="error">
    {{ error }}
  </div>
</template>


<script>
import axios from 'axios';

export default {
  props: ['isCheckboxChecked'],
  data() {
    return {
      error: '',
      previewImage: '',
      selectedFile: null,
      generating_tb: false,
      thumbnail_id: '',
      name: '',
      gender: '',
      checkbox: false,
      frameImage: null,
      video_file_extention: null,
      uid: null
    };
  },
  created() {
    this.setInitialValuesFromStore();
  },
  methods: {
    emitValuesToParent() {
      this.$store.dispatch('updateVideoData', {
        selectedFile: this.selectedFile,
        name: this.name,
        gender: this.gender,
        checkbox: this.checkbox,
        thumbnail_id: this.thumbnail_id,
        uid: this.uid,
        previewImage: this.previewImage,
        video_file_extention: this.video_file_extention
      });
    },
    setInitialValuesFromStore() {
      this.name = this.$store.state.videoData.name;
      this.gender = this.$store.state.videoData.gender;
      this.checkbox = this.$store.state.videoData.checkbox;
      this.previewImage = this.$store.state.videoData.previewImage;
    },
    emitChanged() {
      this.emitValuesToParent();
      if (this.name.length > 15) {
        this.name = this.name.slice(0, 16);
      }
    },
    fileSelect() {
      this.$refs.fileInput.click();
    },
    delError() {
      this.error = '';
    },
    removeFileSelect() {
      this.selectedFile = null;
    },
    openFileDialog() {
  const input = document.createElement('input');
  input.type = 'file';
  input.accept = 'video/*';
  input.onchange = async (e) => {
    const file = e.target.files[0];
    let fileBaseName, fileExtension;
    [fileBaseName, fileExtension] = file.name.split('.');
    this.video_file_extention = fileExtension
    this.previewImage = await this.extractFrame(file);
  };
  input.click();
},

async extractFrame(file) {
  // Check the file size
  const maxFileSize = 200 * 1024 * 1024;
  if (file.size > maxFileSize) {
    this.error = `File size exceeds the limit (200 MB). Please choose a smaller file.`;
    return;
  }

  this.generating_tb = true;
  this.previewImage = '';
  this.thumbnail_id = '';
  this.uid = '';

  const video = document.createElement('video');
  video.preload = 'metadata';
  video.src = URL.createObjectURL(file);

  // Wait for the video metadata to load
  await new Promise((resolve) => {
    video.onloadedmetadata = resolve;
  });

  // Seek to the 10th second of the video
  video.currentTime = 2;

  // Wait for the video to seek to the desired time
  await new Promise((resolve) => {
    video.onseeked = resolve;
  });

  const canvas = document.createElement('canvas');
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;
  const context = canvas.getContext('2d');
  context.drawImage(video, 0, 0, canvas.width, canvas.height);

  const frameImageBlob = await new Promise((resolve) => {
    canvas.toBlob(resolve, 'image/png');
  });

  this.frameImage = URL.createObjectURL(frameImageBlob);

  const formData = new FormData();
  formData.append('image', frameImageBlob, 'frame.png');

  const accessToken = localStorage.getItem('access_token');

  // Make the API request to generate the thumbnail
  try {
    const response = await axios.post('/generate-thumbnail', formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
    });

    const thumbnailUrl = response.data.data.thumbnail;
    const thumbnailId = response.data.data.thumbnail_id;
    const uid = response.data.data.uid;

    this.generating_tb = false;

    this.thumbnailUrl = thumbnailUrl;
    this.thumbnail_id = thumbnailId;
    this.uid = uid;

    this.previewImage = thumbnailUrl;
    this.selectedFile = file;
    this.emitValuesToParent();

    return thumbnailUrl;
  } catch (error) {
    console.error('Error extracting frame:', error);

    this.error = error.response.data.message;
    this.generating_tb = false;
  }
},
    handleDragOver() {
      this.$refs.dropArea.classList.add('drag-over');
      this.emitValuesToParent();
    },
    handleDragLeave() {
      this.$refs.dropArea.classList.remove('drag-over');
    },
    async handleDrop(event) {
      this.$refs.dropArea.classList.remove('drag-over');

      const file = event.dataTransfer.files[0];

      if (file) {
        this.previewImage = await this.extractFrame(file);
        this.selectedFile = file;
        this.emitValuesToParent();
      }
    },
  },
};
</script>



<style scoped>

.frame {
  padding: 30px;
  min-height: 400px;
  display: flex;
  width: 100%;
  max-width: 900px;
  border-radius: 10px;
}
.frame.upload-area {
  background: white;
  box-shadow: rgb(184 184 184 / 15%) 3px 4px 7px 0px;
}
.text-exp-header {
  color: #4b4b4b;
  font-size: 40px;
  font-weight: 600;
}
.upload-container {
width: 210px;
height: 210px;
border: 5px solid #fff;
display: flex;
justify-content: center;
align-items: flex-end;
cursor: pointer;
background: rgb(239 239 239);
border-radius: 190px;
overflow: hidden;
}
.upload-image {
  width: 180px;
  cursor: pointer;
}

.select-wrapper {
  position: relative;
  width: 100%;
  margin: 10px 0;
}

.select-wrapper::after {
color: black;
content: '▾';
margin-right: 10px;
pointer-events: none;
position: absolute;
right: 10px;
top: 7px;
font-size: 20px;
}



.upload-container {
width: 210px;
height: 210px;
border: 5px solid #fff;
display: flex;
justify-content: center;
align-items: flex-end;
cursor: pointer;
background: rgb(239 239 239);
border-radius: 190px;
overflow: hidden;
}
.card-wrap-empty {
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  flex-direction: column;
  cursor: pointer;
  border: 2px dashed #eee;
}

.card-wrap {
  background: #f7f7f7;
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  flex-direction: column;
}

.upload-image {
  width: 180px;
  cursor: pointer;
}

.form-container {
  margin-top: 20px;
  width: 100%;
  max-width: 600px;
}

.text-exp-header {
  color: #4b4b4b;
  font-size: 40px;
  font-weight: 600;
}

.select-wrapper {
  position: relative;
  width: 100%;
  margin: 10px 0;
}

.select-wrapper::after {
color: black;
content: '▾';
margin-right: 10px;
pointer-events: none;
position: absolute;
right: 10px;
top: 7px;
font-size: 20px;
}


.select {
-moz-appearance: none;
-webkit-appearance: none;
background: white;
border: none;
border-radius: 0;
cursor: pointer;
padding: 12px;
width: 100%;
font-size: 16px;
border-radius: 5px;
}

.select:focus {
color: black;
}

/* Hack for IE 11+ */
.select::-ms-expand {
display: none;
}


.row {
display: flex;
margin: 10px 0;
flex-direction: column;
align-items: flex-start;
}
.btn:disabled {
opacity: .3;
cursor: default;
}


.row input {
border: 1px solid #cfd3d3;
border-radius: 20px;
height: 63px;
}

.select {
border: 1px solid #cfd3d3;
border-radius: 20px;
padding: 12px 20px;
}

.input-label{
margin-bottom: -11px;
margin-left: 26px;
background: #f7f7f7;
z-index: 3;
font-size: 14px;
padding: 0px 5px;
}

.checkbox-wrap {
  display: flex;
  align-items: center;
  font-size: 13px;
  text-align: left;
  gap: 13px;
  margin: 20px 0px;
}

.checkbox-wrap input{
  width: 30px;
}

input[type="checkbox"] {
width: 30px;
height: 30px;
}

.spinner-wrap {
  height: 100px;
  width: 200px;
}
.error {
  color: #ff4b4b;
  min-height: 25px;
}
@media only screen and (max-width: 768px) {
  .frame{
    padding: 30px 0px;
  }
  .frame.upload-area{
    box-shadow:none;
  }
  .text-exp-header[data-v-6e18b924] {
    font-size: 30px;
}
.card-wrap{
  background: #fff;
}
}
</style>

<template> 
    <div class="delete-dialog-overlay" @click="Save">
      <div class="delete-dialog" @click.stop>
        <div @click="Save" class="close">
          <img src="@/assets/icons/x.svg" alt="close" />
        </div>


        <div class="setting-area">
          <div class="section-area bots">
          <h3 >Select avatar</h3>
          <p >Select the avatar to use for your video.</p>
          <bots @selected_bot="handleBotSelect" @selected_bot_tb="handleBotTbSelect" @selected_bot_is_ready="handleBotIsReady"></bots>
          </div>

          <div class="section-area">
          <h3 >Position</h3>
          <p >Select where you want the avatar to appear on the background.</p>
          <div class="position-options">
                <div @click="selectPosition('bottom-right')" class="frame frame-bottom-right" :class="{ 'selected-pos': 'bottom-right' === position }">
                  <img v-if="selected_bot_tb" :src="selected_bot_tb" alt="play" />
                </div>
                <div @click="selectPosition('bottom-left')" class="frame frame-bottom-left" :class="{ 'selected-pos': 'bottom-left' === position }">
                  <img v-if="selected_bot_tb" :src="selected_bot_tb" alt="play" />
                </div>
                <div @click="selectPosition('top-left')" class="frame frame-top-left" :class="{ 'selected-pos': 'top-left' === position }">
                  <img v-if="selected_bot_tb" :src="selected_bot_tb" alt="play" />
                </div>
                <div @click="selectPosition('top-right')" class="frame frame-top-right" :class="{ 'selected-pos': 'top-right' === position }">
                  <img v-if="selected_bot_tb" :src="selected_bot_tb" alt="play" />
                </div>
                
              </div>
          </div>
            
          <div class="section-area">
          <h3 >Avatar background</h3>
          <p >Choose whether to keep or remove the background behind the avatar.</p>
          <div class="background-options">
              <div class="box" :class="{ 'selected-box': remove_bg }" @click="removeBg()"> 
                Remove
              </div>
          </div>
          </div>  

          <div class="section-area">
          <h3 >Video transitions</h3>
          <p >Select the video transition style .</p>
          <div class="transition-options">
            <div class="box" :class="{ 'selected-box': fade_in }" @click="selectFadeIn()"> 
              Fade in
            </div>
            <div class="box" :class="{ 'selected-box': fade_out }" @click="selectFadeOut()">
              Fade Out
            </div>
          </div>
          </div>

        </div>
        <div class="button-container">
          <button @click="Save" class="btn">Save</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Bots from '@/components/main/Bots.vue';

  export default {
    components: {
    Bots,
    },
    data() {
      return {
        selected_bot: '',
        selected_bot_tb: '',
        selected_bot_is_ready: false,
        position: 'bottom-right',
        remove_bg: true,
        fade_in: false,
        fade_out: false,
      }
    },
    mounted() {
    this.setInitialValuesFromStore();
    },
    methods: {
      setInitialValuesFromStore() {
        this.selected_bot = this.$store.state.settingsData.selected_bot;
        this.selected_bot_tb = this.$store.state.settingsData.selected_bot_tb;
        this.position = this.$store.state.settingsData.position;
        this.remove_bg = this.$store.state.settingsData.remove_bg;
        this.fade_in = this.$store.state.settingsData.fade_in;
        this.fade_out = this.$store.state.settingsData.fade_out;
        this.selected_bot_is_ready = this.$store.state.settingsData.selected_bot_is_ready;
      },  
    Save() {
      this.$emit('close');
      this.$store.dispatch('updateSettingsData', {
        selected_bot: this.selected_bot,
        selected_bot_tb: this.selected_bot_tb,
        selected_bot_is_ready: this.selected_bot_is_ready,
        position: this.position,
        remove_bg: this.remove_bg,
        fade_in: this.fade_in,
        fade_out: this.fade_out,
      });
    },
    handleBotSelect(data) {
      this.selected_bot = data;
    },
    handleBotTbSelect(data) {
      this.selected_bot_tb = data;
    },
    handleBotIsReady(data) {
      this.selected_bot_is_ready = data;
    },
    selectPosition(position) {
      this.position = position;
    },
    removeBg() {
      this.remove_bg = !this.remove_bg;
    },
    selectFadeIn() {
      this.fade_in = !this.fade_in;
    },
    selectFadeOut() {
      this.fade_out = !this.fade_out;
    },
    },
  };
  </script>
  
  <style scoped>
  .delete-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    max-width: none;
  }
  
  .delete-dialog {
    background: white;
    padding: 45px;
    border-radius: 8px;
    margin: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    width: 60%;
    max-width: 800px;
  }
  
  .button-container {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
  }
  .btn-del, .btn{
  height: 40px;
}

.btn {
  border-radius: 10px;
  margin: 0px 0px;
  width: 150px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.fade-enter-to, .fade-leave-from {
  opacity: 1;
}

.close img {
  width: 15px;
}
.close {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}
.setting-area {
    text-align: left;
    max-height: 60vh;
    overflow: scroll;
    padding: 0px 20px;
    margin: 20px 0px;
}


.position-options {
    display: flex;
    gap: 5px;
    margin: 10px 0px;
}
.selected-pos, .selected-box {
  border: 2px solid rgb(201 201 201);
}

.frame {
  display: flex;
  padding: 5px;
  background: #efefef;
width: 145px;
    height: 83px;
  border-radius: 5px;
  padding: 5px;
}
.frame-bottom-right {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.frame-bottom-left {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}


.frame-top-left {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.frame-top-right {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.frame:hover {
  background: #e0e0e0;
}

.frame img {
  width: 40px;
  border-radius: 10px;
}

.section-area {
  margin-bottom: 30px;
}

.box {
  background: #efefef;
  border-radius: 5px;
  padding: 12px;
  font-size: 15px;
  color: black;
  height: 50px;
  width: 115px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box:hover {
    background: #e0e0e0;
}

.background-options, .transition-options {
    display: flex;
    gap: 10px;
    margin: 10px 0;
}

.bots{
  min-height: 199px;
}

@media only screen and (max-width: 768px) {

  .delete-dialog {
    padding: 24px 5px;
    width: 95%;
}

.frame {
    padding: 5px;
    height: 50px;
    padding: 1px;
}

.frame img {
    width: 24px;
    border-radius: 7px;
}
}
  </style>
  
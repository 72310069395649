<template>
    <div>
      <div class="wrap-spinner">
        <div class="spinner"></div>
        <h2>Thank You for Your Payment</h2>
        <p>Your payment has been successfully processed.</p>
        <p>Redirecting to the main page...</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    mounted() {
      setTimeout(() => {
        // Redirect to the main page after 2 seconds
        this.$router.push({ name: 'main' });
      }, 2000);
    },
  };
  </script>
  
<style scoped>
  .wrap-spinner {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  </style>
  
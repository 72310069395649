<template>
    <div>
        <TopBar></TopBar>

        <div @click="delError" :class="{'main-wrapper': $store.state.toggle, 'main-wrapper-closed': !$store.state.toggle}">
          <div v-if="!submit" class="card-wrap">
                <StepFour></StepFour>
                <div class="btn-wrap">
                  <button v-if="!submit" class="btn" @click="submitForm" :disabled="!$store.state.voiceData.checkbox || !$store.state.voiceData.selectedFileAudio  && !$store.state.voiceData.audioBlob">Update voice</button>
                  <div v-if="submit" class="spinner"></div>
                </div>
            </div>

            <div v-if="submit" class="card-wrap">
                  <div class="spinner"></div>
                </div>
            </div>

        </div>

  </template>
  
  <script>
  import axios from 'axios';
  import StepFour from '@/components/createbot/StepFour.vue';
  import TopBar from '@/components/topbar/TopBar.vue';

  export default {
    components: {
        StepFour,
        TopBar

    },
    props: {
      id: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        selectedFileAudio: null,
        audioBlob: null,
        checkbox: false,
        isRecording: false,
        audio: null,
        submit: false
      };
    },
    created() {
    this.emitValuesToParent();
  },
    methods: {
      emitValuesToParent() {
        this.$store.dispatch('updateVoiceData', {
          selectedFileAudio: this.selectedFileAudio,
          checkbox: this.checkbox,
          isRecording: this.isRecording,
          audioBlob: this.audioBlob,
        });
    },

      async submitForm() {
      this.submit = true;


      if (this.$store.state.voiceData.selectedFileAudio) {
          this.audio = this.$store.state.voiceData.selectedFileAudio;
        } else if (this.$store.state.voiceData.audioBlob) {
          const audioFile = await fetch(this.$store.state.voiceData.audioBlob).then((res) => res.blob());
          this.audio = audioFile;
        } else {
          console.warn('No audio file selected or generated.');
        }

      const accessToken = localStorage.getItem('access_token');


      try {
          const file_name_audio = 'avatar-audio.' + this.$store.state.voiceData.audio_file_extention;
          const response = await axios.post('/upload-url-avatar', { fileName: file_name_audio, uid: this.id }, {
            headers: { Authorization: `Bearer ${accessToken}` },
          });
          const url = response.data.url;
          this.audio_file_key = response.data.file_key;

          const formData = new FormData();
          formData.append('file', this.audio);

          const uploadResponse = await axios.put(url, this.audio, {
            headers: { 'Content-Type': 'binary/octet-stream' },
          });

          this.audio_ext = this.$store.state.voiceData.audio_file_extention;

          console.log('Upload Response:', uploadResponse.data);
          console.log('Audio uploaded successfully!');
        } catch (error) {
          console.error('Error uploading audio:', error);
          this.message = '';
          this.submit = false;
        }

        const botFormData = new FormData();
        botFormData.append('audio_file_key', this.audio_file_key);
        botFormData.append('audio_ext', this.audio_ext);
        botFormData.append('public', this.$store.state.publicData.public);

      // Send form data to the backend using Axios
      axios.put(`/v1/bot/${this.id}`, botFormData, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          // Extract the bot_id from the response
          const botId = response.data.data.bot_id;

          // Navigate to the '/bot/:id' route with the extracted bot_id
          this.$router.push({ name: 'bot', params: { id: botId } });
        })
        .catch((error) => {
          this.error = error.response.data.message;
          this.submit = false;
        });
    }
    }
  };
  </script>
  

<style scoped>

.card-wrap {
  background: #f7f7f7;
    width: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 30px 0px;
    flex-direction: column;
    min-height: calc(100vh - 130px);
    box-shadow: rgb(184 184 184 / 24%) 0px 3px 7px 0px;
    justify-content: center;
  }
  .btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.btn-wrap {
  max-width: 700px;
}

.spinner {
    width: 30px;
    height: 30px;
}
</style>
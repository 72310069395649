<template>
    <div>
          <section class="section-wrap">
            <div class="title">
              Subscription plan
            </div>
            <div class="card">
              <div class="sub-type">
                {{ $store.state.auth.userData.subscription }}
              </div>
              <div class="credit">
                User - {{ $store.state.auth.userData.email }}
            </div>
              <div class="credit">
                Remaining balance - {{ $store.state.auth.userData.credit }}
              </div>    



              <router-link
                    :to="{ name: 'subscribe' }"
                    class="upgrade">
                    Buy credits
            </router-link>
            </div>
          </section>
    </div>
  </template>

  <script>

  export default {
    components: {
    },
    
  };
  </script>
  
  
    
  <style scoped>

.card {
    background: #f7f7f7;
    border-radius: 5px;
    padding: 20px;
    box-shadow: rgb(184 184 184 / 15%) 0px 4px 7px 0px;
}
section {
  margin: 15px 0;
}

.title {
  font-size: 14px;
}

.sub-type {
    margin: 10px 0px;
    color: #436ac5;
    font-size: 16px;
}

.upgrade {
  display: flex;
    background: linear-gradient(157deg, #9b51e0, #0693E3);
    width: 130px;
    color: white;
    align-items: center;
    justify-content: center;
    padding: 7px;
    border-radius: 5px;
    margin: 10px 0px;
    font-size: 16px;
}
.upgrade:hover {
  background: linear-gradient(25deg, #6c36a0, #0b83c9);
}
  </style>
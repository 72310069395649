<template>

  <div class="frame">
    <div class="card-wrap">
      <div class="row">
        <VoiceGuidelines></VoiceGuidelines>
                <div class="input-label">
                  Voice
                </div>
                <div class="record-wrap">
                    <button @click="newSentence" class="redo-btn redo">
                      <img src="@/assets/icons/redo.svg" alt="redo" />
                    </button>
                    <div class="text-box">
                      <div class="text">
                        {{ sentence }}
                      </div>
                    </div>
                    <input
                      ref="fileInput"
                      type="file"
                      accept="audio/*"
                      style="display: none"
                      @change="handleFileChange"
                    />
                    <div v-if="isRecording">Seconds: {{ countdown }}</div>
                      <button v-if="!isRecording && !audioBlob && !selectedFileAudio" class="recording-btn selectfile" @click="fileSelect"><img src="@/assets/icons/upload.svg" alt="file" /></button>
                      <button v-if="!isRecording && !audioBlob && selectedFileAudio" class="recording-btn selectfile" @click="removeFileSelect"><img src="@/assets/icons/remove.svg" alt="file" /></button>
                      <button v-if="audioBlob && !isRecording" class="recording-btn play" v-show="!isPlaying" @click="playRecording"><img src="@/assets/icons/play.svg" alt="play" /></button>
                      <button v-if="!isRecording && !audioBlob && !selectedFileAudio" class="recording-btn record" @click="toggleRecording"><div class="red-dot"></div></button>
                      <button v-if="isRecording" class="recording-btn record" @click="stopRecording"><div class="square"></div></button>
                      <button v-if="!isRecording && audioBlob" class="recording-btn delete" @click="deleteRecording"><img src="@/assets/icons/remove.svg" alt="remove" /></button>
                    </div>

                    <div class="checkbox-wrap">
                      <input v-model="checkbox" type="checkbox" name="ownershipConfirmation" id="ownershipConfirmation" @change="emitChanged" />
                      I hereby confirm that I am the rightful owner of the voice content I am providing. I affirm
                      that I possess all necessary rights, permissions, and licenses required to authorize of this material.
                    </div>
            </div>

    </div>
  </div>
  <div class="error">
            {{ error }}
    </div>
</template>

<script>
import VoiceGuidelines from '@/components/createbot/VoiceGuidelines.vue';

export default {
  props: ['isCheckboxChecked'],
  components: {
    VoiceGuidelines,
    },
  data() {
    return {
      audio_file_extention: null,
      error: '',
      checkbox: false,
      isRecording: false,
      countdown: 8,
      audioBlob: null,
      mediaRecorder: null,
      selectedFileAudio: null,
      audioContext: '',
      sentence: "",
      sentences: [
        "Cookies smell good when they bake in the oven; they taste even better.",
        "Walk up the mountain trail, and you'll see amazing views all around.",
        "At the market, people sell fruits and veggies with bright colors and nice smells.",
        "On the beach at sunrise, waves come in gently, and birds fly above.",
        "In the city, tall buildings stand, and cars make a steady hum in the streets.",
        "By the campfire, friends talk and tell stories under the stars.",
      ]
    };
  },
  created() {
    this.newSentence();
    this.setInitialValuesFromStore();
  },

  methods: {
    fileSelect() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {

      const selectedFile = event.target.files[0];
      let fileBaseName, fileExtension;
      [fileBaseName, fileExtension] = selectedFile.name.split('.');
      this.audio_file_extention = fileExtension
      const maxFileSize = 50 * 1024 * 1024;

        if (selectedFile.size > maxFileSize) {
          this.error = 'File size exceeds the limit (50MB). Please choose a smaller file.';
          return;
        }

      if (selectedFile) {
        this.selectedFileAudio = selectedFile;
        this.emitValuesToParent(); 
      }
    },
    newSentence() {
      const randomIndex = Math.floor(Math.random() * this.sentences.length);
      this.sentence = this.sentences[randomIndex];
    },
    removeFileSelect() {
      this.selectedFileAudio = null;
      this.emitValuesToParent(); 
    },
    toggleRecording() {
      if (this.isRecording) {
        // Stop recording and release microphone access
        this.mediaRecorder.stop();
        this.audioContext.close();
        this.isRecording = false;
      } else {
        // Start recording and request microphone access
        this.isRecording = true;
        this.countdown = 8;
        this.audioBlob = null;

        navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
          this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
          // const input = this.audioContext.createMediaStreamSource(stream);

          this.mediaRecorder = new MediaRecorder(stream);

          this.mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
              this.audioBlob = URL.createObjectURL(new Blob([event.data], { type: 'audio/wav' }));
              this.audio_file_extention = 'wav'
              this.emitValuesToParent(); 
            }
          };

          this.mediaRecorder.onstop = () => {
            this.isRecording = false;
          };

          this.mediaRecorder.start();

          const countdownInterval = setInterval(() => {
            this.countdown -= 1;
            if (this.countdown <= 0) {
              clearInterval(countdownInterval);
              this.mediaRecorder.stop();
            }
          }, 1000);
        });
      }
    },
    stopRecording() {
      // Stop recording when the stop button is clicked
      if (this.isRecording) {
        this.mediaRecorder.stop();
        this.audioContext.close();
        this.isRecording = false;
      }
    },
    deleteRecording() {
      this.audioBlob = null;
      this.emitValuesToParent(); 
    },
    playRecording() {
      if (this.audioBlob) {
        this.isPlaying = true;

        const audioPlayer = new Audio(this.audioBlob);
        audioPlayer.play();

        audioPlayer.onended = () => {
          // Hide the stop button and show the play button after playback ends
          this.isPlaying = false;
        };
      } else {
        console.warn("No recording available to play.");
      }
    },
    stopAudio() {
      // Stop the audio playback
      this.isPlaying = false;
    },
    emitValuesToParent() {
        this.$store.dispatch('updateVoiceData', {
          selectedFileAudio: this.selectedFileAudio,
          checkbox: this.checkbox,
          isRecording: this.isRecording,
          audioBlob: this.audioBlob,
          audio_file_extention: this.audio_file_extention
        });
    },
    emitChanged() {
      this.emitValuesToParent();
    },
    setInitialValuesFromStore() {
    this.selectedFileAudio = this.$store.state.voiceData.selectedFileAudio;
    this.isRecording = this.$store.state.voiceData.isRecording;
    this.checkbox = this.$store.state.voiceData.checkbox;
    this.audioBlob = this.$store.state.voiceData.audioBlob;
    },
  },
};
</script>


<style scoped>

.frame {
  padding: 20px;
  min-height: 400px;
  display: flex;
  width: 100%;
  max-width: 800px;
  justify-content: center;
  align-items: center;

}

.row {
  display: flex;
  margin: 10px 0;
  flex-direction: column;
  align-items: flex-start;
}

.record-wrap{
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #cfd3d3;
  border-radius: 20px;
  gap: 5px;
  padding: 5px 10px;
  cursor: text;
  position: relative;
  height: 100px;
}

.recording-btn {
  padding: 10px;
  border-radius: 50px;
  width: 36px;
  min-width: 36px;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0;
  border: 0px solid #5e5e5e;
}

.recording-btn:hover {
  background: #e2e2e2;
}

.redo-btn {
  padding: 10px;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  min-width: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0;
  border: 0px solid #5e5e5e;
}

.delete img {
  width: 16px;
}

.selectfile img {
  width: 18px;
}

.play img {
  width: 16px;
}

.redo img {
  width: 16px;
}
.text-box {
  width: 100%;
  margin: 0 5px;
}
.text {
  text-align: left;
  max-width: 545px;
  font-size: 14px;
}
.red-dot {
    background: red;
    height: 10px;
    width: 10px;
    border-radius: 10px;
}
.square {
    background: red;
    height: 10px;
    width: 10px;
    border-radius: 2px;
}


.row input {
  border: 1px solid #cfd3d3;
  border-radius: 20px;
  height: 63px;
}

.select {
  border: 1px solid #cfd3d3;
  border-radius: 20px;
  padding: 12px 20px;
}

.input-label{
  margin-bottom: -11px;
  margin-left: 26px;
  background: #f7f7f7;
  z-index: 3;
  font-size: 14px;
  padding: 0px 5px;
}
.checkbox-wrap {
    display: flex;
    align-items: center;
    font-size: 13px;
    text-align: left;
    gap: 13px;
    margin: 20px 0px;
}

.checkbox-wrap input{
    width: 30px;
}

input[type="checkbox"] {
  width: 30px;
  height: 30px;
}

.error {
    color: #ff4b4b;
    min-height: 25px;
}

@media only screen and (max-width: 768px) {
  .record-wrap{
    height: 190px;
  }
  .frame{
    padding: 0px;
  }
}
</style>

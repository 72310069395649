<template>
    <div class="delete-dialog-overlay" @click="cancel">
      <div class="delete-dialog" @click.stop>
        <div @click="cancel" class="close">
          <img src="@/assets/icons/x.svg" alt="close" />
        </div>
        <p>Before you dive into creating your amazing video,</p>
        <p>let's set the stage by creating your first avatar.</p>
        <div class="button-container">
          <button @click="toCreateBot" class="btn">Create Avatar</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    methods: {
      cancel() {
        this.$emit('cancel');
      },
      toCreateBot() {
      this.$router.push({ name: 'newBot' });
    },
    },
  };
  </script>
  
  <style scoped>
  .delete-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }
  
  .delete-dialog {
    background: white;
    padding: 45px;
    border-radius: 8px;
    max-width: 540px;
    margin: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .button-container {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
  }
  .btn-del, .btn{
  height: 40px;
}

.btn {
  border-radius: 10px;
  margin: 20px 0px;
  width: 150px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.fade-enter-to, .fade-leave-from {
  opacity: 1;
}

.close img {
  width: 15px;
}
.close {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 35px;
    cursor: pointer;
}
  </style>
  
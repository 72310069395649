<template>
 
    <div :class="{'dialog-overlay': $store.state.toggle, 'dialog-overlay-closed': !$store.state.toggle}">
        <div  class="spinner"></div>
    </div>
  </template>
  
  <script>
  export default {
  };
  </script>
  
  <style scoped>
  .dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100% - 300px);
    height: 100%;
    background: rgb(255 255 255 / 95%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    margin-left: 300px;
  }

  .dialog-overlay-closed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(255 255 255 / 95%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
  }
  
  </style>
  
<template>
    <div>


    <div class="section">
        <p>To create your personalized avatar, simply record yourself in front of the camera.</p>
    </div>    

    <div class="section">
        <div class="title">
            <h3>Camera Choice</h3>
        </div>
        <div class="text">
            Use a professional camera for optimal results.
            Mobile phones or webcams are acceptable alternatives.
        </div>
    </div>

    <div class="section">
        <div class="title">
            <h3>Environment and Lighting</h3>
        </div>
        <div class="text">
            Choose a well-lit environment, natural light is preferable, position yourself facing the light source.
        </div>
    </div>

    <div class="section">
        <div class="title">
            <h3>Duration</h3>
        </div>
        <div class="text">
            Record a minimum of one minute of video footage.
        </div>
    </div>


    <div class="section">
        <div class="title">
            <h3>Eye Contact</h3>
        </div>
        <div class="text">
            Maintain eye contact with the camera throughout the recording and avoid looking in other directions.
        </div>
    </div>

    <div class="section">
        <div class="title">
            <h3>Body Movements</h3>
        </div>
        <div class="text">
            Stand still during the recording, avoid excessive movement or walking.
        </div>
    </div>


    <div class="section">
        <div class="title">
            <h3>Hand Gestures</h3>
        </div>
        <div class="text">
            Minimize extreme hand movements, keep hands within the frame and avoid overly expressive gestures. 
        </div>
    </div>

    <div class="section">
        <div class="title">
            <h3>Resolution</h3>
        </div>
        <div class="text">
            We recommend to record in 1080p resolution for clear and detailed footage.
        </div>
    </div>

    <div class="section">
        <div class="title">
            <h3>Video File Format</h3>
        </div>
        <div class="text">
            Preferred formats are MP4, AVI, or MKV for compatibility.
        </div>
    </div>

    <div class="section">
        <div class="title">
            <h3>Audio File Format</h3>
        </div>
        <div class="text">
            Preferred formats are MP3 or WAV.
        </div>
    </div>

    <div class="section">
        <div class="title">
            <h3>File size</h3>
        </div>
        <div class="text">
            Keep video file size below 200 megabytes.
        </div>
    </div>

    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
.section {
    margin: 25px 0;
}
.text {
    font-size: 14px;
    font-weight: 100;
}

.section p{
    font-weight: 100;
}
</style>
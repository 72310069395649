<template>
  <div>
    <div class="bots-wrap">

      <div
        class="bot-item"
        v-if="bots"
        v-for="bot in bots.slice(0, 10)"
        :key="bot.id"
        @click="selectBot(bot.uid, bot.thumbnail, bot.is_ready)"
        @mouseenter="setShowSettings(bot.uid, true)"
        @mouseleave="setShowSettings(bot.uid, false)"
        :class="{ 'selected': bot.uid === selected_bot }"
      >
        <router-link v-if="getShowSettings(bot.uid)" class="settings" :to="{ name: 'bot', params: { id: bot.uid } }"></router-link>
        <div class="bot-image" :class="{ 'in-process': !bot.is_ready }">
          <img v-if="bot" :src="bot.thumbnail" alt="Thumbnail" loading="lazy">
        </div>
        <div  class="bot-name">
          {{ bot.name }}
        </div>
        
        <div v-if="!bot.is_ready" class="spin">
          <div class="spinner spinner-not-ready"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      bots: [],
      selected_bot: '',
      selected_bot_tb: '',
      selected_bot_is_ready: false,
      botSettings: {}, 
    };
  },
  created() {
    this.setInitialValuesFromStore();
  },
  methods: {
    setInitialValuesFromStore() {
      this.bots = this.$store.state.settingsData.bots;
      this.selected_bot = this.$store.state.settingsData.selected_bot;
      this.selected_bot_tb = this.$store.state.settingsData.selected_bot_tb;
      this.$emit('selected_bot', this.selected_bot);
      this.$emit('selected_bot_tb', this.selected_bot_tb);
      this.$emit('selected_bot_is_ready', this.selected_bot_is_ready);
    },
    selectBot(uid, thumbnail, is_ready) {
      this.selected_bot = uid;
      this.selected_bot_tb = thumbnail;
      this.$emit('selected_bot', uid);
      this.$emit('selected_bot_tb', thumbnail);
      this.$emit('selected_bot_is_ready', is_ready);
    },
    setShowSettings(botId, value) {
      // Set showSettings state for the specific bot
      this.botSettings[botId] = value;
    },
    getShowSettings(botId) {
      // Get showSettings state for the specific bot
      return this.botSettings[botId] || false;
    },
  },
};
</script>


    
  <style scoped>
  .spinner-not-ready {
    width: 30px;
    height: 30px;
  }
  
  .bots-wrap {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 25px;
      margin: 20px 0;
      flex-wrap: wrap;
  }
  
  .bot-item {
    box-shadow: rgb(184 184 184 / 15%) 3px 4px 7px 0px;
    width: 125px;
    height: 125px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    background: rgb(239 239 239);
    border-radius: 190px;
    overflow: hidden;
    position: relative;
    align-items: center;
  }

  .bot-item:hover {
    background: #dddddd;
  }
  .bot-image img {
    width: 110px;
    margin-bottom: -30px;
  }
  
  .bot-name {
    font-size: 9px;
    font-weight: 600;
    z-index: 10;
    position: absolute;
    bottom: 10px;
    background: #f7f7f7;
    padding: 2px;
    border-radius: 3px;
    color: #1e1e1e;
  }
  .selected {
    background: #dddddd;
    border: 2px solid #cfd3d3;
  }
  
  .in-process img {
    filter: blur(3.5px);
  }
  .spin {
    position: absolute;
  }

  .settings {
    position: absolute;
    top: 15px;
    right: 20px;
    padding: 3px;
}

.settings img{
    width: 15px;
}

@media only screen and (max-width: 768px) {
  .bot-item {
    width: 80px;
    height: 80px;
  }
  .bot-image img {
    width: 82px;
  }
}
    </style>
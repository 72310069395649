<template>
    <div>
        <section class="section-wrap">
        <div class="title">
            Information
        </div>
        <div class="card">
            <div class="link">
            <a href="https://aipixy.com/about/" target="_blank">About us</a>
            </div>
            <div class="link">
            <a href="https://aipixy.com/pricing/" target="_blank">Pricing</a>
            </div>
            <div class="link">
            <a href="https://aipixy.com/privacy-policy/" target="_blank">Privacy policy</a>
            </div>
            <div class="link">
            <a href="https://aipixy.com/terms-conditions/" target="_blank">Terms of service</a>
            </div>
            <div class="link">
            <a href="https://aipixy.com/help/" target="_blank">Help</a>
            </div>
            <div class="link">
            <a href="https://aipixy.com/talk-to-support/" target="_blank">Contact us</a>
            </div>
        </div>
        </section>
    </div>
  </template>
  
  <script>


  export default {
    components: {
    },
    
  };
  </script>
  
  
    
  <style scoped>
.card {
    background: #f7f7f7;
    border-radius: 5px;
    padding: 20px;
    box-shadow: rgb(184 184 184 / 15%) 0px 4px 7px 0px;
}
section {
  margin: 15px 0;
}

.title {
  font-size: 14px;
}
.link {
    margin: 15px 0;
    font-size: 16px;
}

.link a {
  color: #024b99;
}

.link a:hover {
  color: #0078f8;
}

  </style>
<template>
    <div class="delete-dialog-overlay" @click="cancel">
      <div class="delete-dialog" @click.stop>
        <p>Are you sure you want to delete this {{ itemName }}?</p>
        <p>{{ deletedText }}</p>
        <div class="button-container">
          <button class="btn" @click="cancel">Cancel</button>
          <button class="btn-del" @click="confirm">Delete</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
    itemName: {
      type: String,
      required: true,
    },
    deletedText: {
      type: String,
      required: true,
    },
  },
    methods: {
      confirm() {
        this.$emit('confirm');
      },
      cancel() {
        this.$emit('cancel');
      },
    },
  };
  </script>
  
  <style scoped>
  .delete-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }
  
  .delete-dialog {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    margin: 20px;
  }
  
  .button-container {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  .btn-del, .btn{
  height: 40px;
}

.btn {
  border-radius: 10px;
  max-width: 100px;
}
  </style>
  
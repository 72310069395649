<template>
    <div>
        <div class="container">
        <div class="card">
          <div class="no-videos-row">
            <h3>{{ botInfo.name }}'s videos</h3>
          </div>
          <div v-if="!videos" class="no-videos-row">
            <div class="no-videos">
              <div class="no-video-image">
                <img src="@/assets/logo/180-logo.png" alt="No-videos" />
              </div>
              <div class="head">
                It's empty here
              </div>
              <div class="small">
                You haven't created any videos yet.
              </div>
              <div class="button">
                <button v-if="botInfo.is_ready" @click="toCreate" class="btn" type="submit">Create</button>
              </div>
            </div>
          </div>

          <div v-if="videos" class="row">
            <router-link
              v-for="(video, index) in videos"
              :key="video.uid"
              :to="{ name: 'video', params: { id: video.video_uid } }"
              active-class="active-link"
              class="item-link">
                <div class="video-image">
                  <img class="icon" :src="video.video_thumbnail" alt="video" loading="lazy">
                </div>
                <div class="side">
                  <div class="top-row">
                    <div class="name">{{ video.main_clip_bot_name }}</div>
                  </div>
                  <div class="bottom-row">
                    <div class="info">Create at: {{ video.created_date }}</div>
                  </div>
                </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    botInfo: {
      type: Object, 
      required: true,
    },
  },
  data() {
  return {
    videos: [],
  };
},
  created() {
    this.fetchVideos(); 
  },
  methods: {
    toCreate() {
      this.$router.push({ name: 'main' });
    },
    async fetchVideos() { 
    this.loading = true;

    try {
      // Get the access token from localStorage
      const accessToken = localStorage.getItem('access_token');

      // Make a GET request to your backend API with the authorization header
      const response = await axios.get(`/v1/bot-video-info/${this.botInfo.id}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      // Assuming your API returns data as a list of keys
      const data = response.data.data.videos;
      this.videos = data;
      this.loading = false; // Set loading to false after successful data fetch
    } catch (error) {
      this.loading = false; // Set loading to false in case of an error
      this.error = 'Error fetching data'; // Set the error message
      console.error('Error fetching:', error);
    }
  },
  },
};
</script>

<style scoped>
.container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.card {
  background: #f7f7f7;
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 30px;
  flex-direction: column;
  box-shadow: rgb(184 184 184 / 15%) 3px 4px 7px 0px;
}


.no-videos {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 30vh;
}

.no-videos-row {
  gap: 25px;
  width: 100%;
  display: flex;
  padding: 10px;
}

.no-videos img {
    width: 65px;
} 
.video-image img {
  width: 100%;
  border-radius: 10px;

}

.video-image {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 14px;
}

.head {
  font-size: 17px;
  font-weight: 600;
}
.small {
  font-size: 14px;
}

.button {
  margin: 15px 0;
}

.btn {
  height: 37px;
}


.top-row {
  font-size: 14px;
}

.bottom-row {
  font-size: 12px;
}
.row {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
  margin: 10px 0;
  width: 100%;
}

.item-link {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: inherit;
    border-radius: 8px;
    overflow: hidden;
    background: white;
    box-shadow: rgb(184 184 184 / 15%) 3px 4px 7px 0px;
}

.icon {
  width: 100%;
  height: auto;
}

.side {
  padding: 15px; /* Adjust the padding based on your design */
}

@media (max-width: 1200px) {
  .row {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Show 3 items per row on medium screens */
  }
}

@media (max-width: 900px) {
  .row {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Show 2 items per row on smaller screens */
  }
}

@media (max-width: 600px) {
  .row {
    grid-template-columns: 1fr; /* Show 1 item per row on small screens */
  }
}
</style>
import { createRouter, createWebHistory } from 'vue-router'
import store from '../store';
import MainView from '../views/main/MainView.vue'
import AuthView from '../views/auth/AuthView.vue'
import HandleAuth from '../views/auth/HandleAuth.vue'
import PaymentPros from '../views/payment/PaymentPros.vue'
import Settings from '../views/settings/Settings.vue'
import Profile from '../views/profile/Profile.vue'
import MyBots from '../views/mybots/MyBots.vue'
import CreateBot from '../views/createbot/CreateBot.vue'
import ExploreBots from '../views/explore/ExploreBots.vue'
import Videos from '../views/allvideos/Videos.vue'
import Clips from '../views/clips/Clips.vue'
import Clip from '../views/clip/Clip.vue'
import Video from '../views/video/Video.vue'
import Bot from '../views/bot/Bot.vue'
import Login from '../views/auth/Login.vue'
import EmailSent from '../views/auth/EmailSent.vue'
import ResetSent from '../views/auth/ResetSent.vue'
import ResetPassword from '../views/auth/ResetPassword.vue'
import Signup from '../views/auth/Signup.vue'
import Subscribe from '../views/subscribe/Subscribe.vue'
import ResendEmail from '../views/auth/ResendEmail.vue'
import StoreBot from '../views/storebot/StoreBot.vue'
import UpdateVoice from '../views/voice/UpdateVoice.vue'

const routes = [
  {
    path: '/',
    name: 'main',
    component: MainView,
    meta: { requiresAuth: true },
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthView,
    meta: { requiresAuth: false },
  },
  {
    path: '/handle-auth',
    name: 'HandleAuth',
    component: HandleAuth
  },
  {
    path: '/login:email?',
    name: 'login',
    component: Login
  },
  {
    path: '/signup/:email?',
    name: 'signup',
    component: Signup,
  },
  {
    path: '/verification',
    name: 'verification',
    component: EmailSent,
  },
  {
    path: '/reset-message',
    name: 'reset',
    component: ResetSent,
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: ResetPassword,
  },
  {
    path: '/resend',
    name: 'resend',
    component: ResendEmail,
  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter: (to, from, next) => {
      // Remove the JWT token from localStorage
      localStorage.removeItem('access_token');
      // Redirect to Glivs home page
      window.location.href = 'https://aipixy.com/';
    },
  },
  {
    path: '/videos',
    name: 'videos',
    component: Videos,
    meta: { requiresAuth: true },
  },
  {
    path: '/subscribe',
    name: 'subscribe',
    component: Subscribe,
    meta: { requiresAuth: true },
  },
  {
    path: '/successful-payment',
    name: 'SuccessfulPayment',
    component: PaymentPros,
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: { requiresAuth: true },
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: { requiresAuth: true },
  },
  {
    path: '/my-avatars',
    name: 'myBots',
    component: MyBots,
    meta: { requiresAuth: true },
  },
  {
    path: '/avatar',
    name: 'newBot',
    component: CreateBot,
    meta: { requiresAuth: true },
  },
  {
    path: '/explore-avatars',
    name: 'exploreBots',
    component: ExploreBots,
    meta: { requiresAuth: true },
  },
  {
    path: '/clips',
    name: 'clips',
    component: Clips,
    meta: { requiresAuth: true },
  },
  {
    path: '/clip/:id',
    name: 'clip',
    component: Clip,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/video/:id',
    name: 'video',
    component: Video,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/avatar/:id',
    name: 'bot',
    component: Bot,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/voice/:id',
    name: 'voice',
    component: UpdateVoice,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/store-bot/:id',
    name: 'storeBot',
    component: StoreBot,
    props: true,
    meta: { requiresAuth: true },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach(async (to, from, next) => {

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check the user's authentication status
    await store.dispatch('getUserInfo');

    if (!store.getters['isAuthenticated']) {
      // User is not authenticated, redirect to login
      next({ name: 'auth' });
    } else {
      // User is authenticated, proceed to the route
      next();
    }
  } else {
    // Route doesn't require authentication, proceed
    next();
  }
});


export default router

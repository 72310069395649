<template>
    <div>
        <Delete item-name="account" v-if="showDeleteDialog" @confirm="deleteUser" @cancel="closeDeleteDialog" />  
        <section class="section-wrap">
            <div class="title">
              Others
            </div>
            <div class="card">
              <router-link
                    :to="{ name: 'logout' }"
                    class="option">
                Logout
              </router-link>
              <!-- <div class="option">
                Delete all videos
              </div> -->
              <div @click="openDeleteDialog" class="option">
                Delete account
              </div>
            </div>
          </section>

    </div>
  </template>
  
  <script>
  import Delete from '@/components/Delete.vue';
  import axios from 'axios';

  export default {
    data() {
      return {
        showDeleteDialog: false,
      };
    },
    components: {
      Delete
    },
    methods: {
      openDeleteDialog() {
      // Open the delete dialog
      this.showDeleteDialog = true;
    },
    closeDeleteDialog() {
      // Close the delete dialog
      this.showDeleteDialog = false;
    },
    deleteUser() {
      const accessToken = localStorage.getItem('access_token');

      axios.delete(`/user`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      })
        .then(() => {
          this.$router.push({ name: 'logout' });
        })
        .catch(error => {
          console.error('Error deleting user:', error);
        })
        .finally(() => {

        });
    },
    }
    
  };
  </script>
  
  
    
  <style scoped>

  .card {
    background: #f7f7f7;
    border-radius: 5px;
    padding: 20px;
    box-shadow: rgb(184 184 184 / 15%) 0px 4px 7px 0px;
}
section {
  margin: 15px 0;
}

.title {
  font-size: 14px;
}
.link {
    margin: 15px 0;
    font-size: 16px;
}

.link a {
  color: #024b99;
}

.link a:hover {
  color: #0078f8;
}

.option {
    margin: 15px 0;
    color: #e93a3a;
    font-size: 16px;
    cursor: pointer;
}
  </style>
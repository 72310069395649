<template >
  <div class="input-area" @click="clickInput">
    <div class="file-input-area" @click="openFileSelect">
      <div class="add-image-area">
        <button v-if="!url_input && !selectedFile" class="add-file">
          <img src="@/assets/icons/image-plus.svg" alt="add-file" />
        </button>
        <div class="preview-area" v-if="selectedFile">
          <img v-if="isImage" :src="fileURL" alt="selected-image" />
          <video v-else :src="fileURL" width="300" height="200"></video>
        </div>
      </div>
    </div>

    <div class="text-input-area">
      <div class="{'selected-bot-closed': this.$store.state.auth.userData.bots === 0, 'selected-bot': !(this.$store.state.auth.userData.bots === 0)}">
      <SelectedBot @click="openSettingsDialog"></SelectedBot>
    </div>
    


    <div class="text-area-wrap" @click="focus">
      <div
        
        class="text-input"
        contenteditable
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
        ref="textInput"
        data-placeholder="Type the text for your video - minimum 50 characters."
      ></div>
    </div>

      <div class="send-area">
        <button v-if="!url_box_open" @click="handleCreate" class="create-btn" :disabled="isButtonDisabled">
          <img v-if="!processing_video" src="@/assets/icons/plane.svg" alt="create" />
          <div v-if="processing_video" class="spinner"></div>
        </button>
        <button v-if="url_box_open" @click="handleLoad" class="create-btn" :disabled="!url_input">
          OK
        </button>
      </div>
    </div>
  </div>

  <div class="counter">
    <div v-if="characterCount > 0">
      {{ characterCount }}/{{ maxCharacters }}
    </div>
  </div>
  <div class="error-msg">
    {{ message }}
  </div>
  
  <VideoSettings v-if="showSettingsDialog" @close="saveSettings"/>
</template>


<script>
import axios from 'axios';
import SelectedBot from '@/components/main/SelectedBot.vue';
import UrlPreview from '@/components/main/UrlPreview.vue';
import VideoSettings from '@/components/main/VideoSettings.vue';

export default {
  components: {
      SelectedBot,
      UrlPreview,
      VideoSettings
  },
  data() {
    return {
      input_text: '',
      maxCharacters: 2000,
      accessToken: localStorage.getItem('access_token'),
      processing_video: false,
      url_box_open: false,
      url_input: '',
      showSettingsDialog: false,
      selectedFile: null,
      message: '',
      isImage: false,
      video_file_key: null,
      file_extention: '',
      background_type: '',
    };
  },
  computed: {
    isButtonDisabled() {
      return this.input_text.length < 50 || this.input_text.length > this.maxCharacters || this.selectedFile == null || !this.$store.state.settingsData.selected_bot_is_ready;
    },
    characterCount() {
      return this.input_text.length;
    },
  },
  methods: {

      clickInput() {
      this.message = '';
      this.$emit('inputAreaClick');
      }, 
      deleteUrl() {
          this.url_input = '';
          this.url_box_open = false;
      },
      openUrlbox() {
          this.url_box_open = true;
      },
      focus() {
          this.$refs.textInput.focus();
      },
      handleLoad() {
      if (this.url_input.trim() !== '') {
          this.url_box_open = false;
          this.$refs.UrlPreview.triggerPreview();
          }
      },
      openSettingsDialog() {
      this.showSettingsDialog = true;
      },
      saveSettings(){
      this.showSettingsDialog = false;
      },
      handleInput() {
      // Ensure the text input doesn't exceed the character limit
      if (this.input_text.length > this.maxCharacters) {
        this.input_text = this.input_text.substring(0, this.maxCharacters);
      }

      // Resize the text input area when content changes
      this.$refs.textInput.style.height = 'auto';
      this.$refs.textInput.style.height = this.$refs.textInput.scrollHeight + 'px';

      // Update the enteredText variable
      this.input_text = this.$refs.textInput.textContent.trim();

      // Check if the content is empty to show/hide the placeholder
      this.togglePlaceholder();

      // Restrict input to maxCharacters
      if (this.input_text.length > this.maxCharacters) {
      this.input_text = this.input_text.slice(0, this.maxCharacters);
      this.$refs.textInput.textContent = this.input_text;
      }

    },
    
    handleBlur() {
      // Show the placeholder if the div is blurred and empty
      this.togglePlaceholder();
    },
    togglePlaceholder() {
      const textInput = this.$refs.textInput;
      if (!textInput.textContent.trim()) {
        textInput.setAttribute('data-placeholder', 'Type the text for your video - minimum 50 characters.');
      } else {
        textInput.removeAttribute('data-placeholder');
      }
    },
    getFileType(file) {
      const fileType = file.type.split('/')[0]; // Get the first part of the MIME type (e.g., 'image' or 'video')
      const fileExtension = file.name.split('.').pop().toLowerCase(); // Get the file extension

      if ((fileType === 'image' || fileType === 'video') && fileExtension) {
        return { type: fileType, extension: fileExtension };
      } else {
        console.error('Unsupported file type or missing extension');
        return { type: null, extension: null }; // Or handle the error in a way that makes sense for your application
      }
    },
    openFileSelect() {

      if (this.$store.state.settingsData.bots.length === 0) {
        return;

      }
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'image/*,video/*'; // Accepts image and video files

      this.selectedFile = null;

      fileInput.addEventListener('change', (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
          // Check if the file size is greater than 200MB (200 * 1024 * 1024 bytes)
          if (selectedFile.size > 200 * 1024 * 1024) {
            this.message = 'File size exceeds 200MB. Please choose a smaller file.';
          } else {

            const fileInfo = this.getFileType(selectedFile);

          // Set variables based on the fileInfo object
          this.background_type = fileInfo.type;
          this.file_extention = fileInfo.extension;

            this.selectedFile = selectedFile;

            // Set fileURL to the URL or Blob of the selected file
            this.fileURL = URL.createObjectURL(selectedFile);

            // Determine if the selected file is an image
            this.isImage = selectedFile.type.startsWith('image/');
          }
        }
      });

      // Trigger the file input dialog
      fileInput.click();
    },
    async handleCreate() {
      this.processing_video = true;
      this.isButtonDisabled = true;
      // this.message = 'Uploading ' + this.background_type + 'file';

      const config = {
        headers: {
          'Authorization': `Bearer ${this.accessToken}`,
        },
      };

      try {
        // Step 1: Get pre-signed URL
        const response = await axios.post('/upload-url-video', {
          random_uid_video: '',
          file_extention: this.file_extention
        }, config);

        
        const url = response.data.url;
        this.video_file_key = response.data.file_key;
        this.random_uid_video = response.data.random_uid_video;
        this.random_uid_clip = response.data.random_uid_clip;

        // Step 2: Upload file to S3
        const uploadResponse = await axios.put(url, this.selectedFile, {
          headers: { 'Content-Type': 'binary/octet-stream' },
        });

        console.log('Upload Response:', uploadResponse.data);
        console.log('Video uploaded successfully!');


        // Step 3: Send request to process the data
        const data = {
            process_done_webhook: '',
            create_video: true,
            random_uid_video: this.random_uid_video,
            clips: [{
                  "bot_uid": this.$store.state.settingsData.selected_bot,
                  "text": this.input_text,
                  "bot_position": this.$store.state.settingsData.position,
                  "remove_bot_background": this.$store.state.settingsData.remove_bg,
                  "background": this.video_file_key,
                  "background_type": this.background_type,
                  "background_volume": this.$store.state.settingsData.background_volume,
                  "transitions": `${this.$store.state.settingsData.fade_in ? 'fade_in' : 'None'} ${this.$store.state.settingsData.fade_in ? 'fade_out' : 'None'}`,
                  "reusable_clip": this.$store.state.settingsData.reusable,
                  "clip_name": this.$store.state.settingsData.clip_name,
                  "random_uid_clip": this.random_uid_clip,
              }
            ],
          };

          const createVideoResponse = await axios.post('/v1/create_video_file', data, config);

          // Extract the video_id from the response
          const videoId = createVideoResponse.data.data.video_uid;

          // Navigate to the '/video/:id' route with the extracted video_id
          this.$store.dispatch('getUserVids');
          this.$router.push({ name: 'video', params: { id: videoId } });

          } catch (error) {
          console.error('Error:', error);
          this.message = '';
          this.submit = false;
          this.message = error.response ? error.response.data.message : 'Unknown error';
          } finally {
          this.processing_video = false;
          this.isButtonDisabled = false;
          }
  },
  },
};
</script>

<style scoped>


.text-input {
  width: 100%;
  box-sizing: border-box;
  min-height: 30px; /* Set a minimum height for the text input area */
  padding: 0px 15px;
  outline: none;
  overflow-y: hidden; /* Hide vertical scrollbar */
  resize: none; /* Disable textarea resizing */
  text-align: left;
  max-width: 712px;
}

.input-area {
  display: flex;
  gap: 7px;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.text-input-area {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #cfd3d3;
  border-radius: 20px;
  gap: 5px;
  padding: 15px 17px;
  cursor: text;
  position: relative;
  min-height: 111px;
  box-shadow: 3px 4px 7px 0 hsla(0,0%,72%,.15);
  justify-content: space-between;
}

.file-input-area {
  display: flex;
  align-items: center;
  border: 1px solid #cfd3d3;
  border-radius: 20px;
  gap: 5px;
  padding: 15px 15px;
  cursor: pointer;
  position: relative;
  min-height: 111px;
  min-width: 150px;
  box-shadow: 3px 4px 7px 0 hsla(0,0%,72%,.15);
  justify-content: center;
}

[data-placeholder]:empty:before {
content: attr(data-placeholder);
color: #999;
}

.create-btn {
  background: #242424;
  color: white;
  padding: 10px;
  border-radius: 11px;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  border: none;
  gap: 6px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  margin: 0;
  box-shadow: rgb(184 184 184 / 30%) 3px 4px 7px 0px;
}

.create-btn img {
width: 14px;
margin-left: 2px;
}

.create-btn:disabled {
opacity: .4;
cursor: default;
}

.file-input-area {
  cursor: pointer;
  transition: background 0.3s ease;
}

.add-file {
  border-radius: 50px;
  width: 45px;
  height: 45px;
  display: flex;
  flex-direction: row;
  border: none;
  align-items: center;
  justify-content: center;
  background: none;
  cursor: pointer;
}

.file-input-area:hover {
  background: #f7f8f8;
}

.add-file img {
width: 23px;
}

.counter {
  font-size: 14px;
  margin: 4px;
  min-height: 21px;
}

.textarea-input {
background-color: transparent;
font-size: 15px;
border: none;
max-height: 100px;
overflow-x: hidden;
resize: none;
overflow-y: hidden;
padding: 10px 0;
display: flex;
height: 100%;
justify-content: center;
margin-left: 5px;
align-items: baseline;
}
.textarea-input::placeholder {
font-size: 16px;
color: #999;
}

.preview-area {
    max-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.preview-area img, video {
    border-radius: 10px;
    max-height: 80px;
    max-width: 130px;
}


.create-btn .spinner {
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-top: 3px solid #ffffff;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin 1s linear infinite;
}

.selected-bot {
    min-width: 80px;
}


.text-area-wrap {
    min-height: 80px;
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
}

.error-msg {
    min-height: 27px;
    color: #de1010;
}

@media only screen and (max-width: 768px) {

.input-area {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.text-input {
  font-size: 12px;
}
}
</style>
